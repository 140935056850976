import './TokenhubTokenViewTools.css';
import './../styles.css'
import { ethers, BigNumber, FixedNumber } from "ethers";
import React, { useState, useEffect, useRef } from "react";
import BlacklistIcon from '../../img/icons/blacklist.svg';
import TokenRecoverIcon from '../../img/icons/tokenrecover.svg';
import BlacklistIconGrey from '../../img/icons/blacklistGrey.svg';
import TokenRecoverIconGrey from '../../img/icons/tokenrecoverGrey.svg';
import WhaleProtectionIcon from '../../img/icons/whaleprotection.svg';
import MintIcon from '../../img/icons/mint.svg';
import SnapshotIcon from '../../img/icons/snapshotblue.svg';
import BurnIcon from '../../img/icons/burn.svg';
import PauseIcon from '../../img/icons/pause.svg';
import SearchIcon from '../../img/icons/search.svg';
import ResumeIcon from '../../img/icons/resume.svg';
import FeeIcon from '../../img/icons/fee.svg';
import Constants from '../../constants.json'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
  } from "react-router-dom";



const TokenhubTokenViewTools = (props) => {
    const [tokenData, setTokenData] = React.useState(props.tokenData);
    const [authorizationTime, setAuthorizationTime] = React.useState(props.authorizationTime);
    const [signature, setSignature] = React.useState(props.signature);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [feeSearchTerm, setFeeSearchTerm] = React.useState("");
    const [blacklistSearchResults, setBlacklistSearchResults] = React.useState([]);
    const [feeSearchResults, setFeeSearchResults] = React.useState([]);
    const [whaleProtectionInputDisabled, setwhaleProtectionInputDisabled] = React.useState(true);
    const [availableTokensToBurn, setAvailableTokensToBurn] = React.useState(0.0);
    const [totalSupplyAfterMint, setTotalSupplyAfterMint] = React.useState(0);
    const [mintAmount, setMintAmount] = React.useState("");
    const [currentSupply, setCurrentSupply] = React.useState(tokenData['supply']);


    useEffect(() => {
        setTokenData(props.tokenData);
        if (Object.keys(props.tokenData['protection_details']).length != 0){
            document.getElementById('tokenhub-tvt-whale-lp-input').value = props.tokenData['protection_details']['LP_address'];
            document.getElementById('tokenhub-tvt-whale-max-tokens-on-wallet-input').value = props.tokenData['protection_details']['max_wallet'];
            document.getElementById('tokenhub-tvt-whale-max-tokens-transaction-input').value = props.tokenData['protection_details']['max_trans'];
            setwhaleProtectionInputDisabled(false);
        }
        getAvailableTokensToBurn();
    }, [props.tokenData])
    useEffect(() => {
        setSignature(props.signature)
    }, [props.signature])
    useEffect(() => {
        setAuthorizationTime(props.authorizationTime)
    }, [props.authorizationTime])

    useEffect(() =>{
        getDataFromBlockchain();
    },[])

    async function getDataFromBlockchain(){
        const abi = [
            "function totalSupply() public view returns (uint256)"
        ]
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(tokenData["token_address"], abi, signer)
        contract.totalSupply().then(supply => {
            console.log("supply from blockchain: " + supply);
            let supplyString = "" + supply + ""
            let indexToSlice = supplyString.length - tokenData['decimals']
            console.log("sliced: " + supplyString.slice(0,indexToSlice) + "." + supplyString.slice(indexToSlice))
            setCurrentSupply(supplyString.slice(0,indexToSlice) + "." + supplyString.slice(indexToSlice))
        })
    }


    function addSpaceDelimiters(number){
        ////////////////////////////////////////////////////////////////////// Ładuje glówną stronę po odświeżeniu, lepiej by było to gdzieś indziej przenieść ale zauważyłem ze ta funkcja jest wywoływana prawie odrazu po odświeżeniu
        function RedirectToMain(){
            let history = useHistory();
            history.push("/");
        }
        if(Object.keys(props.tokenData).length === 0){
            RedirectToMain();
        }
        number = parseInt(number);
        number = number + "" //TODO - add support for decimals
        /////////////////////////////////////////////////////////////////////
        if (number.length <= 9){
            if (number.length % 3 == 0){
                return number.replaceAll(/(.{3})/g, ' $1').substring(1); // zaczyna od lewej i daje spacje przed każdemy trzema znakami, w tym przypadku ta pierwsza spacje usuwamy, nizej nie trzeba
            }
            if (number.length % 3 == 1){
                return number.charAt(0) + number.substring(1).replaceAll(/(.{3})/g, ' $1');
            }
    
            if (number.length % 3 == 2){
                return number.charAt(0) + number.charAt(1) + number.substring(2).replaceAll(/(.{3})/g, ' $1');
            }
        }
        else if (number.length <= 12){
            number = number/1000000000
            number = Math.round(number * 100) / 100
            return (number + " Billion") 
        }
        else if (number.length <= 15){
            number = number/1000000000000
            number = Math.round(number * 100) / 100
            return (number + " Trillion") 
        }
        else if (number.length <= 18){
            number = number/1000000000000000
            number = Math.round(number * 100) / 100
            return (number + " Quadrillion") 
        }
        else if (number.length <= 21){
            number = number/1000000000000000000
            number = Math.round(number * 100) / 100
            return (number + " Quintillion") 
        }
        else if (number.length <= 24){
            number = number/1000000000000000000000
            number = Math.round(number * 100) / 100
            return (number + " Sextillion") 
        }
        else if (number.length <= 27){
            number = number/1000000000000000000000000
            number = Math.round(number * 100) / 100
            return (number + " Septillion") 
        }
        else if (number.length <= 30){
            number = number/1000000000000000000000000000
            number = Math.round(number * 100) / 100
            return (number + " Octillion") 
        }
        else{
            number = number/1000000000000000000000000000000
            number = Math.round(number * 100) / 100
            return (number + " Nonillion") 
        }
    }

    function getFeeFeature(){
        const addressItemList = feeSearchResults.map((address) =>
            <div className='tokenhub-tvt-fee-address-list-item'>
                <span className='tokenhub-tvt-fee-address-list-item-address'>{address}</span>
                <button className='tokenhub-tvt-fee-address-list-item-remove-button' onClick={() => props.authorizeThenExecute(removeFeeExcludeAddress,address)}>x</button>
            </div>
        );

        return(
            <div className="tokenhub-tvt-feature-double-width">
                <div className="tokenhub-tvt-feature-header-circle">
                    <img src={FeeIcon} className="tokenhub-tvt-feature-header-icon"/>
                </div>
                <span className='tokenhub-tvt-feature-title'>Fee</span>
                <div className='tokenhub-tvt-input-with-description tokenhub-tvt-fee-transfer'>
                    <span className='tokenhub-tvt-input-label'>Transfer fee</span>    
                    <input type="text" autoComplete="off" className="tokenhub-tvt-input-with-description-input tokenhub-tvt-input" id="tokenhub-tvt-fee-transfer-input" name="fname" placeholder="0" defaultValue={tokenData['fee_specs']['transfer_Fee']}/>
                    <span className='tokenhub-tvt-input-unit'>% of transaction</span>
                </div>
                <div className='tokenhub-tvt-input-with-description tokenhub-tvt-fee-burn'>
                    <span className='tokenhub-tvt-input-label'>Burn fee</span>    
                    <input type="text" autoComplete="off" className="tokenhub-tvt-input-with-description-input tokenhub-tvt-input" id="tokenhub-tvt-fee-burn-input" name="fname" placeholder="0" defaultValue={tokenData['fee_specs']['burn_fee']}/>
                    <span className='tokenhub-tvt-input-unit'>% of transaction</span>
                </div>
                <div className='tokenhub-tvt-input-with-description tokenhub-tvt-fee-lp' hidden={tokenData['fee_specs']['lp_fee'] === "0" }>
                    <span className='tokenhub-tvt-input-label'>LP fee</span>    
                    <input type="text" autoComplete="off" className="tokenhub-tvt-input-with-description-input tokenhub-tvt-input" id="tokenhub-tvt-fee-lp-input" name="fname" placeholder="0" defaultValue={tokenData['fee_specs']['lp_fee']}/>
                    <span className='tokenhub-tvt-input-unit'>% of transaction</span>
                </div>
                <div className='tokenhub-tvt-input-with-description tokenhub-tvt-fee-wallet'>
                    <span className='tokenhub-tvt-input-label'>Fee wallet</span>    
                    <input type="text" autoComplete="off" className="tokenhub-tvt-input-with-description-input tokenhub-tvt-input" id="tokenhub-tvt-fee-wallet-input" name="fname" placeholder="0" defaultValue={tokenData['excluded_from_fee']['fee_wallet']}/>
                </div>
                <span className='tokenhub-tvt-fee-exclude-header'>Exclude from fee</span>
                <input type="text" autoComplete="off" className="tokenhub-tvt-input tokenhub-tvt-fee-exclude-input tokenhub-tvt-input-padding" id="tokenhub-tvt-fee-exclude-input" name="fname" placeholder="Paste address to exclude" />
                <button className='default-button tokenhub-tvt-button tokenhub-tvt-fee-exlude-button' onClick={() => props.authorizeThenExecute(addFeeExcludeAddress)}>Exclude</button>
                <input type="text" autoComplete="off" className="tokenhub-tvt-fee-search-input" id="tokenhub-tvt-fee-search-input" name="fname" placeholder="Search address" onChange={(e) => setFeeSearchTerm(e.target.value)}/>
                <img src={SearchIcon} className='tokenhub-tvt-fee-search-icon'/>
                <div className='tokenhub-tvt-fee-address-list'>
                    {addressItemList}
                </div>
                <button className='default-button tokenhub-tvt-button tokenhub-tvt-fee-save-button' onClick={() => props.authorizeThenExecute(changeFees)}>Save</button>
                {getPauseButton()}
            </div>
        )
    }

    async function changeFees(sig){
        //TODO - add validation
        let transferFee = parseInt(document.getElementById('tokenhub-tvt-fee-transfer-input').value);
        let burnFee = parseInt(document.getElementById('tokenhub-tvt-fee-burn-input').value);
        let lpFee = parseInt(document.getElementById('tokenhub-tvt-fee-lp-input').value);
        let feeWallet = document.getElementById('tokenhub-tvt-fee-wallet-input').value;
        if(transferFee + burnFee + lpFee > 30){
            //TODO - add notification
            return;
        }
        const abi = [
            "function setFees(uint256 burnFee_, uint256 liquidityFee_, uint256 transferFee_, address feeWallet_)"
        ]
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(tokenData["token_address"], abi, signer)
        contract.setFees(burnFee, lpFee, transferFee, feeWallet).then(transaction => {
            console.log(transaction);
            let myObj = {...tokenData};
            myObj["fee_specs"]["lp_fee"] = lpFee;
            myObj["fee_specs"]["burn_fee"] = burnFee;
            myObj["fee_specs"]["transfer_Fee"] = transferFee;
            myObj["excluded_from_fee"]["fee_wallet"] = feeWallet;
            setTokenData(myObj);
            signer.getAddress().then(address => {
                props.setNotificationActive(false);
                props.addNotification("green", "Fees changed successfully", "")
                console.log("request: " + Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                    "&trans_fee=" + transferFee + "&burn_fee=" + burnFee + "&lp_fee=" + lpFee + "&fee_wallet=" + feeWallet)
                fetch(Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                    "&trans_fee=" + transferFee + "&burn_fee=" + burnFee + "&lp_fee=" + lpFee + "&fee_wallet=" + feeWallet).then((response) => {
                    return response.text()}).then((responseText) => {
                        const myObj2 = JSON.parse(responseText)
                        console.log("response: " + responseText);
                    })
            })
        },
        (error) =>{
            console.log("error " + error)
            props.setNotificationActive(false);
            props.addNotification("red", "Failed to change fees", "")
        });
    }

    function getPauseButton(){
        if (tokenData["fee_specs"]["fee_enabled"] == "1"){
            return(
                <button className='default-button-secondary tokenhub-tvt-fee-pause-button' onClick={() => props.authorizeThenExecute(pauseFee)}>
                    <img src={PauseIcon} className='tokenhub-tvt-fee-pause-icon'/>
                    Pause fee
                </button>
            )
        }
        else{
            return(
                <button className='default-button-alert tokenhub-tvt-fee-resume-button' onClick={() => props.authorizeThenExecute(resumeFee)}>
                    <img src={ResumeIcon} className='tokenhub-tvt-fee-pause-icon'/>
                    Resume fee
                </button>
            )
        }
    }

    async function pauseFee(sig){
        const abi = [
            "function disableFees(bool _feesDisabled)"
        ]
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(tokenData["token_address"], abi, signer)
        contract.disableFees(true).then(transaction => {
            props.setNotificationActive(false);
                props.addNotification("green", "Fees paused successfully", "")
            console.log(transaction);
            let myObj = {...tokenData};
            myObj["fee_specs"]["fee_enabled"] = "0";
            setTokenData(myObj);
            signer.getAddress().then(address => {
                console.log("request: " + Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                    "&fee_status=0")
                fetch(Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                    "&fee_status=0").then((response) => {
                    return response.text()}).then((responseText) => {
                        const myObj2 = JSON.parse(responseText)
                        console.log("response: " + responseText);
                    })
            })
        },
        (error) =>{
            console.log("error " + error)
            props.setNotificationActive(false);
            props.addNotification("red", "Failed to pause fees", "")
            //TODO - error handling
        });
    }

    async function resumeFee(sig){
        const abi = [
            "function disableFees(bool _feesDisabled)"
        ]
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(tokenData["token_address"], abi, signer)
        contract.disableFees(false).then(transaction => {
            props.setNotificationActive(false);
            props.addNotification("green", "Fees resumed successfully", "")
            console.log(transaction);
            let myObj = {...tokenData};
            myObj["fee_specs"]["fee_enabled"] = "1";
            setTokenData(myObj);
            signer.getAddress().then(address => {
                console.log("request: " + Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                    "&fee_status=1")
                fetch(Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                    "&fee_status=1").then((response) => {
                    return response.text()}).then((responseText) => {
                        const myObj2 = JSON.parse(responseText)
                        console.log("response: " + responseText);
                    })
            })
        },
        (error) =>{
            console.log("error " + error)
            props.setNotificationActive(false);
            props.addNotification("red", "Failed to pause fees", "")
            //TODO - error handling
        });
    }



    React.useEffect(() => { //do wyszukiwania w fee
        const delayDebounceFn = setTimeout(() => {
            if(feeSearchTerm!=""){
                const result = tokenData['excluded_from_fee']['addresses'].filter((address) => address.toLowerCase().includes(feeSearchTerm.toLowerCase()));
                setFeeSearchResults(result);
            }
            else setFeeSearchResults(tokenData['excluded_from_fee']['addresses']);
        }, 100);
    
        return () => clearTimeout(delayDebounceFn);
    }, [feeSearchTerm]);

    async function addFeeExcludeAddress(sig){
        let feAddress = document.getElementById('tokenhub-tvt-fee-exclude-input').value;
        const abi = [
            "function excludeFromFees(address account, bool excluded)"
        ]
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(tokenData["token_address"], abi, signer)
        contract.excludeFromFees(feAddress, true).then(transaction => {
            props.setNotificationActive(false);
            props.addNotification("green", "Adress excluded", feAddress + " has been successfully excluded")
            console.log(transaction);
            signer.getAddress().then(address => {
                console.log("request: " + Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                "&excluded=" + feAddress)
                fetch(Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                    "&excluded=" + feAddress).then((response) => {
                    return response.text()}).then((responseText) => {
                        const myObj2 = JSON.parse(responseText)
                        console.log("response:" + responseText);
                        let myObj = {...tokenData};
                        myObj['excluded_from_fee']['addresses'].push(feAddress)
                        setTokenData(myObj);
                    })
            })

        },
        (error) => {
            console.log("error " + error)
            //props.setNotificationActive(false);
            props.removeNotification();
            props.addNotification("red", "Failed to exclude address", feAddress + " cannot be excluded")
        });
    }

    async function removeFeeExcludeAddress(sig, addressToRemove){
        const abi = [
            "function excludeFromFees(address account, bool excluded)"
        ]
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(tokenData["token_address"], abi, signer)
        contract.excludeFromFees(addressToRemove, false).then(transaction => {
            props.setNotificationActive(false);
            props.addNotification("green", "Removed excluded address", addressToRemove + " has been removed from the list of addresses excluded from fees")
            console.log(transaction);
            signer.getAddress().then(address => {
                console.log("request: " + Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                "&included=" +  addressToRemove)
                fetch(Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                "&included=" +  addressToRemove).then((response) => {
                    return response.text()}).then((responseText) => {
                        const myObj2 = JSON.parse(responseText)
                        console.log("response:" + responseText);
                        let myObj = {...tokenData};
                        for( var i = 0; i < myObj['excluded_from_fee']['addresses'].length; i++){ 
                            if ( myObj['excluded_from_fee']['addresses'][i] ===  addressToRemove) { 
                                myObj['excluded_from_fee']['addresses'].splice(i, 1); 
                                i--;
                            }
                        }
                        setTokenData(myObj);
                    })
            })

        },
        (error) => {
            console.log("error " + error)
            props.setNotificationActive(false);
                props.addNotification("red", "Task failed", "Could not remove " + addressToRemove + " from the list of addresses excluded from fees")
        });       
    }

    function getFeatureGridItems(){
        let featureGridItems=[];
        if (tokenData['fee'] == "1"){
            featureGridItems.push(getFeeFeature())
        }
        if (tokenData["blacklist"] == "1"){
            featureGridItems.push(getBlacklistFeature());
        }
        if (tokenData["recovery"] == "1"){
            featureGridItems.push(getTokenRecoverFeature());
        }
        if (tokenData["protection"] == "1"){
            featureGridItems.push(getWhaleProtectionFeature());
        }
        if (tokenData["burnable"] == "1"){
            featureGridItems.push(getBurnFeature());
        }
        if (tokenData["snapshots"] == "1"){
            featureGridItems.push(getSnapshotFeature());
        }
        if (tokenData["mintable"] == "1"){
            featureGridItems.push(getMintFeature());
        }

        return(
            <>{featureGridItems}</>
        )
    }

    function getSnapshotFeature(){
        let snapshotList = "";
        let lastSnapshot = "Never";
        if (Object.keys(tokenData['snapshots_list']['snapshots']).length != 0){
            let formattedDateList = [];
            let date = "";
            let dateArr = "";
            let dateString = "";
            for (let i=tokenData['snapshots_list']['snapshots'].length-1; i>=0; i--){
                dateArr = tokenData['snapshots_list']['snapshots'][i].toString().split('.');
                date = new Date(dateArr[0]*1000);
                const options = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  };
                dateString = date.toLocaleString([], options);
                formattedDateList.push(dateString);
                if(lastSnapshot==="Never"){
                    const optionsLast = {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                    };
                    lastSnapshot = date.toLocaleString([], optionsLast)
                }
            }
            snapshotList = formattedDateList.map((snapshot) =>
                <span className='tokenhub-tvt-snapshot-list-item'>{snapshot}</span>
            );
        }

        return(
            <div className="tokenhub-tvt-feature">
                <div className="tokenhub-tvt-feature-header-circle">
                    <img src={SnapshotIcon} className="tokenhub-tvt-feature-header-icon"/>
                </div>
                <span className='tokenhub-tvt-feature-title'>Snapshots</span>
                <span className='tokenhub-tvt-snapshot-last-taken-label'>Last snapshot taken</span>
                <span className='tokenhub-tvt-snapshot-last-taken-date'>{lastSnapshot}</span>
                <span className='tokenhub-tvt-snapshot-history-label'>History</span>
                <div className='tokenhub-tvt-snapshot-list'>
                    {snapshotList}
                </div>
            </div>
        )
    }

    function getBurnFeature(){
        return(
            <div className="tokenhub-tvt-feature">
                <div className="tokenhub-tvt-feature-header-circle">
                    <img src={BurnIcon} className="tokenhub-tvt-feature-header-icon"/>
                </div>
                <span className='tokenhub-tvt-feature-title'>Burn tokens</span>
                <span className='tokenhub-tvt-burn-quantity-available-label'>Available tokens to burn</span>
                <span className='tokenhub-tvt-burn-quantity-available'>{availableTokensToBurn}</span>
                <div className='tokenhub-tvt-input-with-description tokenhub-tvt-burn-amount'>
                    <span className='tokenhub-tvt-input-label'>Amount of tokens to burn</span>
                    <input type="text" autoComplete="off" className="tokenhub-tvt-input-with-description-input tokenhub-tvt-input" id="tokenhub-tvt-burn-amount" name="fname" placeholder="Type number of tokens to burn"/>
                    <button className='default-button tokenhub-tvt-button tokenhub-tvt-burn-button' onClick={() => burnTokens()}>Burn</button>
                </div>
            </div>
        )
    }

    function getMintFeature(){
        return(
            <div className="tokenhub-tvt-feature">
                <div className="tokenhub-tvt-feature-header-circle">
                    <img src={MintIcon} className="tokenhub-tvt-feature-header-icon"/>
                </div>
                <span className='tokenhub-tvt-feature-title'>Mint tokens</span>
                <div className='tokenhub-tvt-input-with-description tokenhub-tvt-mint-address'>
                    <span className='tokenhub-tvt-input-label'>Reciever address of minted tokens</span>
                    <input type="text" autoComplete="off" className="tokenhub-tvt-input-with-description-input tokenhub-tvt-input" id="tokenhub-tvt-mint-address" name="fname" placeholder="Paste reciever address" defaultValue={tokenData['token_creator']}/>
                </div>
                <div className='tokenhub-tvt-input-with-description tokenhub-tvt-mint-amount'>
                    <span className='tokenhub-tvt-input-label'>Amount of tokens to mint</span>
                    <input type="text" autoComplete="off" className="tokenhub-tvt-input-with-description-input tokenhub-tvt-input" id="tokenhub-tvt-mint-amount" name="fname" placeholder="Type number of tokens" defaultValue="0"  onChange={(e) => setMintAmount(e.target.value)}/>
                    <button className='default-button tokenhub-tvt-button tokenhub-tvt-burn-button' onClick={() => mintTokens()}>Mint</button>
                </div>
                <span className='tokenhub-tvt-supply-after-mint-label'>Total supply after mint</span>
                <span className='tokenhub-tvt-supply-after-mint'>{totalSupplyAfterMint}</span>
            </div>
        )
    }

    useEffect(() => {
        if(tokenData['mintable'] == '1'){
            setMintAmount(mintAmount.toString().replace(/\D/g, ""))
            if (mintAmount !== ""){
                setTotalSupplyAfterMint(parseFloat(mintAmount) + parseFloat(currentSupply))
            } 
            else{
                setTotalSupplyAfterMint(parseFloat(currentSupply))
            }
            document.getElementById('tokenhub-tvt-mint-amount').value = mintAmount
        }

    }, [mintAmount, currentSupply])

    async function mintTokens(){
        let mintAmountFN = FixedNumber.from(mintAmount);
        console.log("mintAmountFN: " + mintAmountFN);
        let decimals = BigNumber.from(tokenData['decimals']); //BigNumber jest immutable wiec trzeba zadeklarowac nowa zmienna za kazdym razem
        let ten = BigNumber.from(10);
        let decimalMul = ten.pow(decimals);
        let decimalMulFN = FixedNumber.from(decimalMul);
        let mintAmountFN2 = mintAmountFN.mulUnsafe(decimalMulFN);
        let mintAmountString = mintAmountFN2.toString();
        const arr = mintAmountString.split(".");
        const abi = [
            "function mint(address account, uint256 amount) public virtual"
        ]
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(tokenData["token_address"], abi, signer)
        contract.mint(document.getElementById('tokenhub-tvt-mint-address').value,arr[0]).then(transaction => {
            setCurrentSupply("" + (parseFloat(currentSupply) + parseFloat(mintAmount)));
           console.log(transaction);
           setAvailableTokensToBurn(parseFloat(availableTokensToBurn) + parseFloat(mintAmount))
           setMintAmount(0)
           document.getElementById('tokenhub-tvt-mint-amount').value = "";
           
        },
        (error) => {
            console.log("error " + error)
            props.setNotificationActive(false);
           props.addNotification("red", "Mint token failed", "Please check if the adress is correct")
        });       
    }

    async function getAvailableTokensToBurn(){
        const abi = [
            "function balanceOf(address account) public view returns (uint256)"
        ]
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(tokenData["token_address"], abi, signer)
        const address = await signer.getAddress();
        const balanceOf = await contract.balanceOf(address);
        
        console.log("BalanceOf: " + balanceOf);
        setAvailableTokensToBurn(parseFloat(balanceOf)/Math.pow(10,parseInt(tokenData['decimals'])));
    }

    async function burnTokens(){
         let burnAmount = document.getElementById('tokenhub-tvt-burn-amount').value;
         if(isNaN(burnAmount) || burnAmount > availableTokensToBurn){
            props.setNotificationActive(false);
            props.addNotification("red", "Burn amount too high", "")
            return;
         }
         let burnAmountFN = FixedNumber.from(burnAmount);
         console.log("burnAmountFN: " + burnAmountFN);
         let decimals = BigNumber.from(tokenData['decimals']); //BigNumber jest immutable wiec trzeba zadeklarowac nowa zmienna za kazdym razem
         let ten = BigNumber.from(10);
         let decimalMul = ten.pow(decimals);
         let decimalMulFN = FixedNumber.from(decimalMul);
         let burnAmountFN2 = burnAmountFN.mulUnsafe(decimalMulFN);
         let burnAmountString = burnAmountFN2.toString();
         const arr = burnAmountString.split(".");
         const abi = [
             "function burn(uint256 amount) public virtual"
         ]
         const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
         await provider.send("eth_requestAccounts", []);
         const signer = provider.getSigner();
         const contract = new ethers.Contract(tokenData["token_address"], abi, signer)
         contract.burn(arr[0]).then(transaction => {
            console.log(transaction);
            setAvailableTokensToBurn(availableTokensToBurn - parseFloat(document.getElementById('tokenhub-tvt-burn-amount').value));
            document.getElementById('tokenhub-tvt-burn-amount').value = "";
            setCurrentSupply("" + (parseFloat(currentSupply) - parseFloat(burnAmount)));
         },
         (error) => {
             console.log("error " + error)
             props.setNotificationActive(false);
            props.addNotification("red", "Burn token failed", "")
         });       
    }

    function getWhaleProtectionFeature(){
        return(
            <div className="tokenhub-tvt-feature">
                <div className="tokenhub-tvt-feature-header-circle">
                    <img src={WhaleProtectionIcon} className="tokenhub-tvt-feature-header-icon"/>
                </div>
                <span className='tokenhub-tvt-feature-title'>Whale protection</span>
                <div className='tokenhub-tvt-input-with-description tokenhub-tvt-whale-lp'>
                    <span className='tokenhub-tvt-input-label'>Liquidity pool address</span>    
                    <input type="text" autoComplete="off" className="tokenhub-tvt-input-with-description-input tokenhub-tvt-input" id="tokenhub-tvt-whale-lp-input" name="fname" placeholder="Paste LP address" onChange={() => setwhaleProtectionInputDisabled(false)}/>
                </div>
                <div className='tokenhub-tvt-input-with-description tokenhub-tvt-whale-max-tokens-on-wallet'>
                    <span className={'tokenhub-tvt-input-label ' + getDisabledClassname()}>Max percent of tokens on wallet</span>
                    <input type="text" autoComplete="off" className={"tokenhub-tvt-input-with-description-input tokenhub-tvt-input " + getDisabledClassname()} id="tokenhub-tvt-whale-max-tokens-on-wallet-input" name="fname" placeholder="10" disabled={whaleProtectionInputDisabled}/>
                    <span className={'tokenhub-tvt-input-unit ' + getDisabledClassname()}>% of supply</span>
                </div>
                <div className='tokenhub-tvt-input-with-description tokenhub-tvt-whale-max-tokens-transaction'>
                    <span className={'tokenhub-tvt-input-label ' + getDisabledClassname()}>Max percent of tokens in one transaction</span>    
                    <input type="text" autoComplete="off" className={"tokenhub-tvt-input-with-description-input tokenhub-tvt-input " + getDisabledClassname()} id="tokenhub-tvt-whale-max-tokens-transaction-input" name="fname" placeholder="10" disabled={whaleProtectionInputDisabled}/>
                    <span className={'tokenhub-tvt-input-unit ' + getDisabledClassname()}>% of supply</span>
                </div>
                <button className='default-button tokenhub-tvt-button tokenhub-tvt-whale-save-button' onClick={() => props.authorizeThenExecute(editWhaleProtection)}>Save</button>             
            </div>
        )
    }

    function getDisabledClassname(){
        if (whaleProtectionInputDisabled){
            return 'tokenhub-tvt-disabled';
        }
    }

    async function editWhaleProtection(sig){
        //TODO - add versions for only one change 
        //TODO - add validation
        let lpAddress = document.getElementById('tokenhub-tvt-whale-lp-input').value;
        let maxTokensWallet = document.getElementById('tokenhub-tvt-whale-max-tokens-on-wallet-input').value;
        let maxTokensTransaction = document.getElementById('tokenhub-tvt-whale-max-tokens-transaction-input').value;
        const abi = [
            "function setWhaleProtection(address lpAddress_, uint256 maxWalletPercentage_, uint256 maxTransferPercentage_)"
        ]
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(tokenData["token_address"], abi, signer)
        contract.setWhaleProtection(lpAddress, maxTokensWallet, maxTokensTransaction).then(transaction => {
            props.setNotificationActive(false);
            props.addNotification("green", "Whale protection settings updated", "")
            console.log(transaction);
            signer.getAddress().then(address => {
                console.log("request: " + Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                "&lp=" + lpAddress + "&max_wallet=" + maxTokensWallet + "&max_trans=" + maxTokensTransaction);
                fetch(Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                    "&lp=" + lpAddress + "&max_wallet=" + maxTokensWallet + "&max_trans=" + maxTokensTransaction).then((response) => {
                    return response.text()}).then((responseText) => {
                        const myObj2 = JSON.parse(responseText)
                        console.log("response:" + responseText);
                        let myObj = {...tokenData};
                        myObj['protection_details']['max_trans'] = maxTokensTransaction;
                        myObj['protection_details']['LP_address'] = lpAddress;
                        myObj['protection_details']['max_wallet'] = maxTokensWallet;
                        setTokenData(myObj);
                    })
            })

        },
        (error) => {
            console.log("error " + error)
            props.setNotificationActive(false);
            props.addNotification("red", "Failed to update whale protection settings", "")
        });
    }

    function getBlacklistFeature(){
        if (tokenData['blacklisted']['addresses'].length == 0){
            return(
                <div className="tokenhub-tvt-feature">
                    <div className="tokenhub-tvt-feature-header-circle">
                        <img src={BlacklistIcon} className="tokenhub-tvt-feature-header-icon"/>
                    </div>
                    <span className='tokenhub-tvt-feature-title'>Blacklist</span>
                    <input type="text" autoComplete="off" className="tokenhub-tvt-blacklist-input" id="tokenhub-tvt-blacklist-input" name="fname" placeholder="Paste address to blacklist"/>
                    <button className='default-button tokenhub-tvt-blacklist-button' onClick={() => props.authorizeThenExecute(addBlacklistaddress)}>Add to blacklist</button>
                    <div className='tokenhub-tvt-blacklist-grey-circle'>
                        <img src={BlacklistIconGrey} className='tokenhub-tvt-blacklist-empty-icon'/>
                    </div>
                    <span className='tokenhub-tvt-blacklist-empty-span'>There are no blacklisted addresses</span>
                </div>
            )
        }

        const addressItemList = blacklistSearchResults.map((address) =>
            <div className='tokenhub-tvt-blacklist-address-list-item'>
                <span className='tokenhub-tvt-blacklist-address-list-item-address'>{address}</span>
                <button className='tokenhub-tvt-blacklist-address-list-item-remove-button' onClick={() => props.authorizeThenExecute(removeBlacklistAddress,address)}>x</button>
            </div>
        );
        return(
            <div className="tokenhub-tvt-feature">
                <div className="tokenhub-tvt-feature-header-circle">
                    <img src={BlacklistIcon} className="tokenhub-tvt-feature-header-icon"/>
                </div>
                <span className='tokenhub-tvt-feature-title'>Blacklist</span>
                <input type="text" autoComplete="off" className="tokenhub-tvt-blacklist-input" id="tokenhub-tvt-blacklist-input" name="fname" placeholder="Paste address to blacklist"/>
                <button className='default-button tokenhub-tvt-blacklist-button' onClick={() => props.authorizeThenExecute(addBlacklistaddress)}>Add to blacklist</button>
                <input type="text" autoComplete="off" className="tokenhub-tvt-blacklist-search-input" id="tokenhub-tvt-blacklist-search-input" name="fname" placeholder="Search address" onChange={(e) => setSearchTerm(e.target.value)}/>
                <img src={SearchIcon} className='tokenhub-tvt-blacklist-search-icon'/>
                <div className='tokenhub-tvt-blacklist-address-list'>
                    {addressItemList}
                </div>
            </div>
        )
    }

    async function addBlacklistaddress(sig){
        let bAddress = document.getElementById('tokenhub-tvt-blacklist-input').value;
        const abi = [
            "function blacklist(address account)"
        ]
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(tokenData["token_address"], abi, signer)
        contract.blacklist(bAddress).then(transaction => {
            props.setNotificationActive(false);
            props.addNotification("green", "Added blacklist address", bAddress + " has been blacklisted")
            console.log(transaction);
            signer.getAddress().then(address => {
                console.log("request: " + Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                "&blacklisted=" + bAddress)
                fetch(Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                    "&blacklisted=" + bAddress).then((response) => {
                    return response.text()}).then((responseText) => {
                        const myObj2 = JSON.parse(responseText)
                        console.log("response:" + responseText);
                        let myObj = {...tokenData};
                        myObj['blacklisted']['addresses'].push(bAddress)
                        setTokenData(myObj);
                    })
            })

        },
        (error) => {
            console.log("error " + error)
            props.setNotificationActive(false);
            props.addNotification("red", "Failed to blacklist address", "Could not blacklist " + bAddress)
        });
    }

    async function removeBlacklistAddress(sig, addressToRemove){
        const abi = [
            "function unblacklist(address account)"
        ]
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(tokenData["token_address"], abi, signer)
        contract.unblacklist(addressToRemove).then(transaction => {
            props.setNotificationActive(false);
            props.addNotification("green", "Removed blacklisted address", addressToRemove + " is no longer blacklisted")
            console.log(transaction);
            signer.getAddress().then(address => {
                console.log("request: " + Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                "&unblacklisted=" +  addressToRemove)
                fetch(Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                    "&unblacklisted=" +  addressToRemove).then((response) => {
                    return response.text()}).then((responseText) => {
                        const myObj2 = JSON.parse(responseText)
                        console.log("response:" + responseText);
                        let myObj = {...tokenData};
                        for( var i = 0; i < myObj['blacklisted']['addresses'].length; i++){ 
                            if ( myObj['blacklisted']['addresses'][i] ===  addressToRemove) { 
                                myObj['blacklisted']['addresses'].splice(i, 1); 
                                i--;
                            }
                        }
                        setTokenData(myObj);
                    })
            })

        },
        (error) => {
            console.log("error " + error)
            props.setNotificationActive(false);
            props.addNotification("red", "Could not remove address", addressToRemove + " could not be unblacklisted, please try again later")
        });       



    }

    React.useEffect(() => { //do wyszukiwania w blacklist
        const delayDebounceFn = setTimeout(() => {
            if(searchTerm!=""){
                const result = tokenData['blacklisted']['addresses'].filter((address) => address.toLowerCase().includes(searchTerm.toLowerCase()));
                setBlacklistSearchResults(result);
            }
            else setBlacklistSearchResults(tokenData['blacklisted']['addresses']);
        }, 100);
    
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    function getTokenRecoverFeature(){
        return(
            <div className="tokenhub-tvt-feature">
                <div className="tokenhub-tvt-feature-header-circle">
                    <img src={TokenRecoverIcon} className="tokenhub-tvt-feature-header-icon"/>
                </div>
                <span className='tokenhub-tvt-feature-title'>Token recover</span>
                <div className='tokenhub-tvt-blacklist-grey-circle'>
                        <img src={TokenRecoverIconGrey} className='tokenhub-tvt-blacklist-empty-icon'/>
                </div>
                <span className='tokenhub-tvt-blacklist-empty-span'>There are no tokens to recover</span>
            </div>
        )
    }

    return (
        <div className="tokenhub-tvt-background">
            <div className="tokenhub-tvt-supply-info-grid">
                <div className="tokenhub-tvt-supply-info-grid-item">
                    <span className="tokenhub-tvt-supply-info-grid-item-description">Supply type</span>
                    <span className="tokenhub-tvt-supply-info-grid-item-value">{tokenData['mintable'] === "1" ? "Flexible" : "Fixed"}</span>
                </div>
                <div className="tokenhub-tvt-supply-info-grid-item">
                    <span className="tokenhub-tvt-supply-info-grid-item-description">Initial supply</span>
                    <span className="tokenhub-tvt-supply-info-grid-item-value">{addSpaceDelimiters(tokenData["supply"])}</span>
                </div>
                <div className="tokenhub-tvt-supply-info-grid-item">
                    <span className="tokenhub-tvt-supply-info-grid-item-description">Current supply</span>
                    <span className="tokenhub-tvt-supply-info-grid-item-value">{addSpaceDelimiters(currentSupply)}</span>
                </div>
            </div>
            <div className="tokenhub-tvt-feature-grid">
                {getFeatureGridItems()}
            </div>
        </div>
    )
}

export default TokenhubTokenViewTools;