import './WaitingOverlay.css';
import React, { useState, useEffect, useRef } from "react";
import LoaderIcon from '../img/img_Loader.png';

const WaitingOverlay = (props) => {


    return (
        <div className="overlay-background">
            <div className="overlay-box">
                
                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                <span className='overlay-waiting-for-transaction'>Waiting for transaction</span>
                <span className='overlay-info-text'>As soon as the transaction is accepted, <br></br>your token will be deployed.<br></br>Please do not close this page.</span>
                <div className='overlay-grey-circle'></div>
                <img className='overlay-icon' src={LoaderIcon}/> 
            </div>
        </div>

    )
}

export default WaitingOverlay;