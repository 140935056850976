import './styles.css';
import './BuilderStepThree.css';

import React, { useState, useEffect, useRef, useCallback } from "react";
import StepOneIcon from '../img/cube1.png';
import CheckmarkIcon from '../img/icons/checkmark.svg'
import Constants from '../constants.json'
import { ethers } from "ethers";
import WarningIcon from '../img/icons/warning.svg'
import BurnIcon from '../img/icons/burn.svg'
import MintIcon from '../img/icons/mint.svg'
import BlacklistIcon from '../img/icons/blacklist.svg'
import WhaleProtectionIcon from '../img/icons/whaleprotection.svg'
import PauseIcon from '../img/icons/pause.svg'
import SnapshotIcon from '../img/icons/snapshotblue.svg'
import TokenRecoverIcon from '../img/icons/tokenrecover.svg'
import FeeIcon from '../img/icons/fee.svg'
import CopyrightIcon from '../img/icons/copyright.svg'
import EditIcon from '../img/icons/edit.svg'

function useComponentVisible(initialIsVisible) { // wykrywa klikniecia poza dropdownem zeby go zamknac wtedy
    const [isComponentVisible, setIsComponentVisible] = useState(
      initialIsVisible
    );
    const ref = useRef(null);
  
    const handleHideDropdown = (event) => {
      if (event.key === "Escape") {
        setIsComponentVisible(false);
      }
    };
  
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsComponentVisible(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener("keydown", handleHideDropdown, true);
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("keydown", handleHideDropdown, true);
        document.removeEventListener("click", handleClickOutside, true);
      };
    });
  
    return { ref, isComponentVisible, setIsComponentVisible };
}

const BuilderStepThree = (props) => {

    const [promoCode, setPromoCode] = useState('');
    const [discount, setDiscount] = useState(0);
    
    useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get('promocode');
      setPromoCode(code);
    
      if (code) {
        fetch(`${Constants['database']}/builder/validate_promocode/?code=${code}`)
            .then(response => response.json())
            .then(data => setDiscount(data.discount_in_percentage / 100))
            .catch(error => {
                console.error('Error:', error)
                setDiscount(0)});
      }
    }, []);


    
    

    const [transactionFeeSelected, setTransactionFeeSelected] = React.useState(false);
    const [burnTokensSelected, setBurnTokensSelected] = React.useState(false);
    const [mintTokensSelected, setMintTokensSelected] = React.useState(false);
    const [noCopyrightsSelected, setNoCopyrightsSelected] = React.useState(false);
    const [postEditableSmartContractSelected, setPostEditableSmartContractSelected] = React.useState(false);
    const [blacklistSelected, setBlacklistSelected] = React.useState(false);
    const [recoverySelected, setRecoverySelected] = React.useState(false);
    const [protectionSelected, setProtectionSelected] = React.useState(false);
    const [pausableSelected, setPausableSelected] = React.useState(false);
    const [snapshotSelected, setSnapshotSelected] = React.useState(false);
    const [showRouterSelect, setShowRouterSelect] = React.useState(false);
    const [routerSelected, setRouterSelected] = React.useState("Please chose the DEX you will be using");
    const [transferFeeValue, setTransferFeeValue] = React.useState(5);
    const [lpFeeValue, setlpFeeValue] = React.useState(0);
    const [burnFeeValue, setBurnFeeValue] = React.useState(5);
    const [feeWallet, setFeeWallet] = React.useState("");
    const [swapRouter, setSwapRouter] = React.useState("");
    const blockchainName = props.blockchainName;
    const [walletConnected, setWalletConnected] = React.useState(props.walletConnected);
    const [userWalletAddress,setUserWalletAddress] = React.useState("")
    const [errorText, setErrorText] = React.useState("");
    const [feeWalletError,setFeeWalletError] = React.useState(false);
    const [swapRouterError,setSwapRouterError] = React.useState(false);
    const [priceMultiplier, setPriceMultiplier] = React.useState(0); 
    

    useEffect(() => {
        setTransactionFeeSelected(props.transactionFeeSelected)
    }, [props.transactionFeeSelected])
    useEffect(() => {
        setBurnTokensSelected(props.burnTokensSelected)
    }, [props.burnTokensSelected])
    useEffect(() => {
        setMintTokensSelected(props.mintTokensSelected)
    }, [props.mintTokensSelected])
    useEffect(() => {
        setNoCopyrightsSelected(props.noCopyrightsSelected)
    }, [props.noCopyrightsSelected])
    useEffect(() => {
        setPostEditableSmartContractSelected(props.postEditableSmartContractSelected)
    }, [props.postEditableSmartContractSelected])
    useEffect(() => {
        setBlacklistSelected(props.blacklistSelected)
    },[props.blacklistSelected])
    useEffect(() => {
        setRecoverySelected(props.recoverySelected)
    },[props.recoverySelected])
    useEffect(() => {
        setProtectionSelected(props.protectionSelected)
    },[props.protectionSelected])
    useEffect(() => {
        setPausableSelected(props.pausableSelected)
    },[props.pausableSelected])
    useEffect(() => {
        setSnapshotSelected(props.snapshotSelected)
    },[props.snapshotSelected])
    useEffect(() => {
        setlpFeeValue(props.lpFeeValue)
    },[props.lpFeeValue])
    useEffect(() => {
        setBurnFeeValue(props.burnFeeValue)
    },[props.burnFeeValue])
    useEffect(() => {
        setTransferFeeValue(props.transferFeeValue)
    },[props.transferFeeValue])
    
    useEffect(() => {
        async function updateUserWalletAddress(){
            const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            var address = await signer.getAddress()
            setUserWalletAddress(address);
        }
        if(props.walletConnected==true){
            updateUserWalletAddress();
        }
        setWalletConnected(props.walletConnected)
    },[props.walletConnected])

    useEffect(() => { //pobieranie cen
        console.log("fetching: + " + Constants['database'] + '/builder/features_prices/')
        const response = fetch(Constants['database'] + '/builder/features_prices/').then(function(response) {
            return response.text()
            }).then((responseText) => {
                const myObj = JSON.parse(responseText)
                console.log(myObj[Constants["tickers"][blockchainName]])
                if(Constants['chainData'][blockchainName]['testnet'] === 'yes'){
                    setPriceMultiplier(myObj[Constants["tickers"][blockchainName]].toPrecision(4) * 0.01);
                    props.setPriceMultiplier(myObj[Constants["tickers"][blockchainName]].toPrecision(4) * 0.01)
                }
                else{
                    setPriceMultiplier(myObj[Constants["tickers"][blockchainName]].toPrecision(4));
                    props.setPriceMultiplier(myObj[Constants["tickers"][blockchainName]].toPrecision(4))
                }
            });
    },[])
    
    

    function backButtonClicked(event){
        props.moveToPreviousStep(true);
        props.stepThreeActive(false);
        props.scrollToTop();
    }

    function nextButtonClicked(event){
        let error=false;
        setFeeWalletError(false);
        setSwapRouterError(false);
        setErrorText("")
        props.setTransactionFeeSelected(transactionFeeSelected);
        props.setBurnTokensSelected(burnTokensSelected);
        props.setMintTokensSelected(mintTokensSelected);
        props.setNoCopyrightsSelected(noCopyrightsSelected);
        props.setPostEditableSmartContractSelected(postEditableSmartContractSelected);
        props.setBlacklistSelected(blacklistSelected);
        props.setRecoverySelected(recoverySelected);
        props.setProtectionSelected(protectionSelected);
        props.setSnapshotSelected(snapshotSelected);
        props.setPausableSelected(pausableSelected);
        props.setlpFeeValue(lpFeeValue);
        props.setBurnFeeValue(burnFeeValue);
        props.setTransferFeeValue(transferFeeValue);
        if(transactionFeeSelected){
            if(!ethers.utils.isAddress(document.getElementById('step-three-fee-wallet-input').value)){
                setErrorText("Fee wallet is not a correct wallet address")
                setFeeWalletError(true)
                error=true;
            }
            
            props.setFeeWallet(document.getElementById('step-three-fee-wallet-input').value);
            if(lpFeeValue == 0){
                props.setSwapRouter("0x0000000000000000000000000000000000000000")
            }
            else if(routerSelected==="Custom"){
                if(!ethers.utils.isAddress(document.getElementById('step-three-custom-swap-router-input').value)){
                    setErrorText("Swap router address is not a correct address")
                    setSwapRouterError(true)
                    error=true;
                }
            }
            else if(routerSelected==="Please chose the DEX you will be using"){
                setErrorText("Please input a swap router to handle LP fee's")
                setSwapRouterError(true)
                error=true;
            }
            else{
                props.setSwapRouter(swapRouter);
            }
        }
        if(error){
            return;
        }
        props.moveToNextStep(true);
        props.stepThreeActive(false);
        props.setStepFourWasActive(true);
        props.scrollToTop();
        
    }
    const SwapRouterSelectBox = (props) => {
        const {
            ref,
            isComponentVisible,
            setIsComponentVisible
          } = useComponentVisible(props.showRouterSelect);
        useEffect(() => {
            props.setShowRouterSelect(isComponentVisible)
        }, [isComponentVisible])
        useEffect(() => {
            setIsComponentVisible(props.showRouterSelect)
        }, [props.showRouterSelect])
        const listItems = Constants['chainData'][blockchainName]['swaps'].map((swap) => 
            <div className='step-three-router-select-item' onClick={() => changeRouter(swap.name)}>
                <span className='step-three-router-select-item-span'>{swap.name}</span>
            </div>
        )
        return(
            <div ref={ref}>
            {isComponentVisible && (
                <div className="step-three-router-select-box" ref={ref}>
                    <div className='step-three-router-select-item-top' onClick={() => changeRouter(routerSelected)}>
                        <span className='step-three-router-select-item-span-top'>{routerSelected}</span>
                    </div>
                    <svg className="step-three-router-select-box-seperator" width="456px" height="1">
                        <rect width="100%" height="1" />
                    </svg>
                    <div className='step-three-router-select-items'>
                        {listItems}
                        <div className='step-three-router-select-item' onClick={() => changeRouter('Custom')}>
                            <span className='step-three-router-select-item-span'>Custom</span>
                        </div>
                    </div>
                </div>
                
                )}
            </div>

        )
    }
    const swapRouterSelect = () => {
        return(
            <>
                <span className='step-three-swap-router-input-description'>Swap router</span>
                <div className='step-three-fee-router-select' onClick={swapRouterDropdownClicked}>
                    <span className='step-three-fee-router-selected'>{routerSelected}</span>
                    <svg height="8" width="15" className ="step-three-fee-router-select-arrow">
                        <polygon points="0,0 15,0 7.5,8 " fill="#5f758e" stroke="#5f758e"/>
                    </svg>
                </div>
            </>
        )
    }

    function changeRouter(name){
        setRouterSelected(name);
        setShowRouterSelect(false);
        if(name!=="Custom"){
            for(var i=0; i<Constants['chainData'][blockchainName]['swaps'].length; i++){
                if(Constants['chainData'][blockchainName]['swaps'][i].name === name){
                    setSwapRouter(Constants['chainData'][blockchainName]['swaps'][i].address)
                }
            }
        }
    }
    
    const swapRouterDropdownClicked = () =>  {
        console.log("test")
        if(showRouterSelect){
            setShowRouterSelect(false);
        }
        else {
            setShowRouterSelect(true);
        }
    }

    function selectedFeatureComponent(title, description, price, setSelected){
        console.log(discount)
        let FeaturePrice = title === "Base" 
        ? Constants["feature prices"][title].toFixed(2) 
        : (Constants["feature prices"][title] * (1 - discount)).toFixed(2);
        return (
            <div className='step-three-feature-div-selected' onClick={() => setSelected(false)}>
                <div className='step-three-feature-circle-selected'></div>
                <img src={CheckmarkIcon} className='step-three-feature-icon-selected'/>
                <span className='step-three-feature-title-selected'>{title}</span>
                <span className='step-three-feature-description-selected'>{description}</span>
                {Constants["feature prices"][title]!=0 ? (
                <div>
                    {title === "Base" ? (
                        <span>
                            <span className='step-three-feature-price before-discount'>$49.00</span>
                            <span className='step-three-feature-price-selected'>${FeaturePrice}</span>
                        </span>
                    ) : (
                        <span className='step-three-feature-price-selected'>${FeaturePrice}</span>
                    )}
                </div>
            ):<></>}
                
            </div>
        )
        
    }

    function unSelectedFeatureComponent(img, title, description, price, setSelected){
        let FeaturePrice = (Constants["feature prices"][title] * (1 - discount)).toFixed(2)
        const originalPrice = Constants["feature prices"][title].toFixed(2);
        return (
            <div className='step-three-feature-div' onClick={() => setSelected(true)}>
                <div className='step-three-feature-circle'>
                <img src={img} className='step-three-feature-icon'/>
                </div>
                
                <span className='step-three-feature-title'>{title}</span>
                <span className='step-three-feature-description'>{description}</span>
                {Constants["feature prices"][title]!=0 ? (
                <div>
                    {discount > 0 ? (
                        <span>
                            <span className='step-three-feature-price before-discount'>${originalPrice}</span>
                            <span className='step-three-feature-price'>${FeaturePrice}</span>
                        </span>
                    ) : (
                        <span className='step-three-feature-price'>${FeaturePrice}</span>
                    )}
                </div>
            ):<></>}
                
            </div>
        )
    }

    function getCustomSwapRouterInput(){
        
        return(
            <div className='input-default-with-description step-three-fee-custom-swap-router-div'>
                <span className='input-default-description'>Swap router address</span>
                <input className={"step-three-custom-swap-router-input input-default-description-input ".concat(swapRouterError?"input-error":"")} type="text" autoComplete="off" id="step-three-custom-swap-router-input" name="fname" placeholder="Paste swap router address"/>
            </div>
        )
    }

    function transferFeeChange(e){
        if(lpFeeValue+burnFeeValue+Number(e)<=30){
            setTransferFeeValue(Number(e))
        }
        else{
            setTransferFeeValue(30-lpFeeValue-burnFeeValue)
        }
    }
    function lpFeeChange(e){
        if(transferFeeValue+burnFeeValue+Number(e)<=30){
            setlpFeeValue(Number(e))
        }
        else{
            setlpFeeValue(30-transferFeeValue-burnFeeValue)
        }
    }
    function burnFeeChange(e){
        if(lpFeeValue+transferFeeValue+Number(e)<=30){
            setBurnFeeValue(Number(e))
        }
        else{
            setBurnFeeValue(30-lpFeeValue-transferFeeValue)
        }
    }
    function getBottomErrorText(){
        if(errorText!==""){
            return(
                <div className='step-one-bottom-error'>
                    <img src={WarningIcon} className="step-one-error-warning-icon" alt="error"/>
                    <span className='step-one-error-text'>{errorText}</span>
                </div>
                
            )
        }
    }

    function getFeeExpanded(title, description, price, setSelected){
        
        return (
            <div className='step-three-fee-expand'>
                <div className='step-three-feature-circle-selected' onClick={() => setSelected(false)}></div>
                <img src={CheckmarkIcon} className='step-three-feature-icon-selected' onClick={() => setSelected(false)}/>
                <span className='step-three-feature-title' onClick={() => setSelected(false)}>{title}</span>
                <span className='step-three-feature-description' onClick={() => setSelected(false)}>{description}</span>
                <div className='step-three-fee-div-for-unselect' onClick={() => setSelected(false)}/>
                <div className='input-default-with-description step-three-fee-wallet-div'>
                    <span className='input-default-description'>Fee wallet</span>
                    <input className={'step-three-fee-wallet-input input-default-description-input '.concat(feeWalletError?"input-error":"")} type="text" autoComplete="off" id="step-three-fee-wallet-input" name="fname" placeholder="Paste fee wallet address" defaultValue={userWalletAddress}/>
                </div>
                {lpFeeValue!=0 && swapRouterSelect()}
                {showRouterSelect ? <SwapRouterSelectBox showRouterSelect={showRouterSelect} setShowRouterSelect={setShowRouterSelect} /> : null}
                {routerSelected === "Custom" && lpFeeValue!=0 && getCustomSwapRouterInput()}
                <span className='step-three-fee-precentage-header'>Fee percentage</span>
                <div className='step-three-fee-slider-grid'>
                    <div className='step-three-fee-slider-grid-item'>
                        <span className='step-three-fee-slider-grid-item-description'>Transfer</span>
                        <input type='range' min='0' max='30' value={transferFeeValue} className='step-three-fee-slider' id='step-three-transfer-fee-slider' onChange={(e) => transferFeeChange(e.target.value)}/>
                        <span className='step-three-fee-percentage' style={{left : (16 + Math.floor(transferFeeValue*3.5))+'px'}}>{transferFeeValue + '%'}</span>
                    </div>
                    <div className='step-three-fee-slider-grid-item'>
                        <span className='step-three-fee-slider-grid-item-description'>Liquidity Provider</span>
                        <input type='range' min='0' max='30' value={lpFeeValue} className='step-three-fee-slider' id='step-three-lp-fee-slider' onChange={(e) => lpFeeChange(e.target.value)}/>
                        <span className='step-three-fee-percentage' style={{left : (16 + Math.floor(lpFeeValue*3.5))+'px'}}>{lpFeeValue + '%'}</span>
                    </div>
                    <div className='step-three-fee-slider-grid-item'>
                        <span className='step-three-fee-slider-grid-item-description'>Burn</span>
                        <input type='range' min='0' max='30' value={burnFeeValue} className='step-three-fee-slider' id='step-three-burn-fee-slider' onChange={(e) => burnFeeChange(e.target.value)}/>
                        <span className='step-three-fee-percentage' style={{left : (16 + Math.floor(burnFeeValue*3.5))+'px'}}>{burnFeeValue + '%'}</span>
                    </div>
                </div>
                <span className='step-three-max-fee-info'>Maximum of 30% to be used in total</span>
            </div>
        )
    }


    return (
        <div className="step-three-background">
            <span className="step-three-header">Step 3</span>
            <span className="step-three-features-span base-token">Token</span> 
            { selectedFeatureComponent("Base","Base price for ERC-20 token",0)}

            <span className="step-three-paid-features-title-span">Free features</span> 
            
            { burnTokensSelected ? selectedFeatureComponent("Burn tokens","Burn tokens after transaction to make it deflationary",0, setBurnTokensSelected) :
             unSelectedFeatureComponent(BurnIcon, "Burn tokens","Burn tokens after transaction to make it deflationary",0, setBurnTokensSelected) }

            { pausableSelected ? selectedFeatureComponent("Pausable","Pausable transfers and allowances",0, setPausableSelected) :
             unSelectedFeatureComponent(PauseIcon, "Pausable","Pausable transfers and allowances",0, setPausableSelected) }

            { snapshotSelected ? selectedFeatureComponent("Snapshots","Create snapshots of wallet balances and supply",0, setSnapshotSelected) :
             unSelectedFeatureComponent(SnapshotIcon, "Snapshots","Create snapshots of wallet balances and supply",0, setSnapshotSelected) }

            { blacklistSelected ? selectedFeatureComponent("Blacklist address","Prevent addresses from interacting with your token",0, setBlacklistSelected) :
             unSelectedFeatureComponent(BlacklistIcon, "Blacklist address","Prevent addresses from interacting with your token",0, setBlacklistSelected) }

            <span className='step-three-paid-features-title-span'>
                Paid features {discount > 0 ? `(Discount: ${(discount * 100).toFixed(0)}%)` : ""}
            </span>

            { transactionFeeSelected ? getFeeExpanded("Transaction Fee","Charge fee for every transaction made with your token",0, setTransactionFeeSelected) :
             unSelectedFeatureComponent(FeeIcon, "Transaction Fee","Charge fee for every transaction made with your token",0, setTransactionFeeSelected) }

            { mintTokensSelected ? selectedFeatureComponent("Mint tokens","Lets you mint additional tokens",0, setMintTokensSelected) :
             unSelectedFeatureComponent(MintIcon, "Mint tokens","Lets you mint additional tokens",0, setMintTokensSelected) }

            { recoverySelected ? selectedFeatureComponent("Token recover","Lets you withdraw other tokens sent to the contract",0, setRecoverySelected) :
             unSelectedFeatureComponent(TokenRecoverIcon, "Token recover","Lets you withdraw other tokens sent to the contract",0, setRecoverySelected) }

            { protectionSelected ? selectedFeatureComponent("Whale protection","Set limits on how many tokens an address can have",0, setProtectionSelected) :
             unSelectedFeatureComponent(WhaleProtectionIcon, "Whale protection","Set limits on how many tokens an address can have",0, setProtectionSelected) }

            { noCopyrightsSelected ? selectedFeatureComponent("No copyrights","Remove Blockfactory copyright from smartcontract",0.3, setNoCopyrightsSelected) :
             unSelectedFeatureComponent(CopyrightIcon, "No copyrights","Remove Blockfactory copyright from smartcontract",0.3, setNoCopyrightsSelected) }

            {/* postEditableSmartContractSelected ? selectedFeatureComponent("Post-editable smart contract","Let you edit smart contract after deploy",0.2, setPostEditableSmartContractSelected) :
             unSelectedFeatureComponent(EditIcon, "Post-editable smart contract","Let you edit smart contract after deploy",0.2, setPostEditableSmartContractSelected) */}


            <button className="step-three-back-button default-button-secondary" onClick={backButtonClicked}>
                Back
            </button>
            <button className="step-three-next-button default-button" onClick={nextButtonClicked}>
                Next
            </button>
            {getBottomErrorText()}
        </div>

    )
}

export default BuilderStepThree;