import './Tokeninfo.css';
import { ethers } from "ethers";
import React, { useState, useEffect, useRef } from "react";

const Tokeninfo = (props) => {
    const [gui, setGui] = React.useState("May take up to 30 seconds to appear");
    const [contractAddress, setContractAddress] = React.useState(props.contractAddress);
    const [tokenName, setTokenName] = React.useState(props.tokenName);
    const [symbol, setSymbol] = React.useState(props.symbol);
    const [decimals, setDecimals] = React.useState(props.decimals);
    const [maximumSupply, setMaximumSupply] = React.useState(props.maximumSupply);
    const [blockchainName, setBlockchainName] = React.useState(props.blockchainName);
    const [burnTokensSelected, setBurnTokensSelected] = React.useState(props.burnTokensSelected);
    const [mintTokensSelected, setMintTokensSelected] = React.useState(props.mintTokensSelected);
    const [blacklistSelected, setBlacklistSelected] = React.useState(props.blacklistSelected);
    const [recoverySelected, setRecoverySelected] = React.useState(props.recoverySelected);
    const [protectionSelected, setProtectionSelected] = React.useState(props.protectionSelected);
    var mintable = 0;
    var burnable = 0;
    var blacklist = 0;
    var recovery = 0;
    var protection = 0;

    
    const [verifiedStatus, setVerifiedStatus] = React.useState("No");
    const [firstRender, setFirstRender] = useState(true);


    useEffect(() => {
        setContractAddress(props.contractAddress);
        setTokenName(props.tokenName); 
        setSymbol(props.symbol);
        setDecimals(props.decimals);
        setMaximumSupply(props.maximumSupply);
        setBlockchainName(props.blockchainName);
        setBurnTokensSelected(props.burnTokensSelected);
        setMintTokensSelected(props.mintTokensSelected);
        setBlacklistSelected(props.blacklistSelected);
        setRecoverySelected(props.recoverySelected);
        setProtectionSelected(props.protectionSelected);
        if(mintTokensSelected){mintable=1};
        if(burnTokensSelected){burnable=1};
        if(blacklistSelected){blacklist=1};
        if(recoverySelected){recovery=1};
        if(protectionSelected){protection=1};
        fetchGui();
        console.log("I fire once");
 
    }, [props.contractAddress])

    useEffect(() => {
        if (gui.length==50){
            checkVerificationStatus()
        }
        
    }, [gui])

    async function fetchGui(){
        console.log("fetching gui");
        console.log('https://coinbuild.pythonanywhere.com/builder/verify/?decimals='+ decimals +'&supply=' + maximumSupply + '&mintable=' + mintable + '&burnable=' + burnable + '&protection=' + protection + '&blacklist=' + blacklist + '&recovery=' + recovery +
            '&pausable=0&snapshots=0&name='+ tokenName + '&ticker=' + symbol + "&contract=" + contractAddress + "&chain=ropsten")
        await fetch('https://coinbuild.pythonanywhere.com/builder/verify/?decimals='+ decimals +'&supply=' + maximumSupply + '&mintable=' + mintable + '&burnable=' + burnable + '&protection=' + protection + '&blacklist=' + blacklist + '&recovery=' + recovery +
            '&pausable=0&snapshots=0&name='+ tokenName + '&ticker=' + symbol + "&contract=" + contractAddress + "&chain=ropsten").then(function(responseTwo) {
            console.log("gui fetched");
            return responseTwo.text();
        }).then(function(response){
            console.log(response);
            var statusJSON = JSON.parse(response);
            setGui(statusJSON.status);
        })
    }

    async function checkVerificationStatus(){
        const apikey = "SUCE6WVVPQKZ4MU6748C62KI9VCC8GZG7Z";
        console.log('https://api-ropsten.etherscan.io/api?apikey=SUCE6WVVPQKZ4MU6748C62KI9VCC8GZG7Z&guid='+gui+'&module=contract&action=checkverifystatus');
        await fetch('https://api-ropsten.etherscan.io/api?apikey=SUCE6WVVPQKZ4MU6748C62KI9VCC8GZG7Z&guid='+gui+'&module=contract&action=checkverifystatus').then(function(responseTwo) {
            console.log("gui fetched");
            return responseTwo.text();
        }).then(function(response){
            console.log(response);
            var statusJSON = JSON.parse(response);
            setVerifiedStatus(statusJSON.result);
        })

    }

    return (
        <div className="tokeninfo-background">
            <table className="tokeninfo-table">
                <tr className='tokeninfo-table-row'>
                    <th>Parameter</th>
                    <th>Value</th>
                </tr>
                <tr className='tokeninfo-table-row'>
                    <td>Token name</td>
                    <td>{tokenName}</td>
                </tr >
                <tr className='tokeninfo-table-row'>
                    <td>Contract address</td>
                    <td>{contractAddress}</td>
                </tr >
                <tr className='tokeninfo-table-row'>
                    <td>GUID</td>
                    <td>{gui}</td>
                </tr>
                <tr className='tokeninfo-table-row'>
                    <td>Is verified?</td>
                    <td>{verifiedStatus}</td>
                </tr>
            </table>
            <button onClick={() => fetchGui()}>Retry GUI</button>
            <button onClick={() => checkVerificationStatus()}>Check if verified</button>
        </div>
    )
}

export default Tokeninfo;