import './TokenhubTokenViewContract.css';
import React, { useState, useEffect, useRef } from "react";
import EtherscanIcon from '../../img/icons/etherscan.svg';

const TokenhubTokenViewContract = (props) => {
    const [tokenData, setTokenData] = React.useState(props.tokenData);
    useEffect(() => {
        setTokenData(props.tokenData);
    }, [props.tokenData])

    function getFiles(){
        let fullString = tokenData['contracts_ours']
        let fullStringSplit = fullString.split('.sol\':')
        let titles = [];
        let prevSplitted = "";
        let codes = [];
        let title = "";
        let code = "";
        for(let i = 1; i<fullStringSplit.length; i++){
            prevSplitted = fullStringSplit[i-1].split('\'')
            title = prevSplitted[prevSplitted.length-1]
            code = fullStringSplit[i].substring(0,fullStringSplit[i].lastIndexOf('\''))
            console.log('title: ' + title);
            console.log('code: ' + code);
        }
        //code = code.replaceAll('\\n', '<br/>')
        code = code.replaceAll('\'', '')
        code = code.replaceAll('"', '')
        const codeFinal = code;
        return(
            <div className="tokenhub-tvc-file">
                {codeFinal}
            </div>
        )
    }


    return (
        <div className="tokenhub-tvc-background">
            <span className='tokenhub-tvc-your-contract'>Your contract</span>
            <span className='tokenhub-tvc-original-contract'>Original contract</span>
            <span className='tokenhub-tvc-etherscan-link'>Etherscan</span>
            <img src={EtherscanIcon} className='tokenhub-tvc-etherscan-icon'/>
            <div className="tokenhub-tvc-file-grid">
                {getFiles()}
            </div>
        </div>
    )
}

export default TokenhubTokenViewContract;