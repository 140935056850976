import './Tokenhub.css';
import './../styles.css';
import React, {useEffect} from "react"
import { ethers } from "ethers";
import StepThreeIconInactive from '../../img/cube3_disabled.png';
import tokenIcon from '../../img/coinLogos/BNB.png'
import LinkButton from '../LinkButton'
import Constants from '../../constants.json'
import StepFourIcon from '../../img/cube4.png';
import LoaderIcon from '../../img/img_Loader.png'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
  } from "react-router-dom";



const Tokenhub = (props) => {
    const [walletConnected, setWalletConnected] = React.useState(props.walletConnected);
    const [numberOfTokens, setNumberOfTokens] = React.useState(0);
    const [allTokenData, setAllTokenData] = React.useState({});
    const [unlimitedSlots, setUnlimitedSlots] = React.useState(0);
    const [tokenData, setTokenData] = React.useState({});
    const [tokensLoading, setTokensLoading] = React.useState(false);
    const history = useHistory();


    useEffect(() => {
        setWalletConnected(props.walletConnected);
        if(props.walletConnected == true){
            setTokensLoading(true);
            getCoins();
        }
    }, [props.walletConnected])

    async function getCoins(){
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        await signer.getAddress().then((address) => {
            console.log("fetching: + " + Constants['database'] + '/builder/getwalletinfo/?address=' + address)
            const response = fetch(Constants['database'] + '/builder/getwalletinfo/?address=' + address).then(function(response) {
                return response.text()
                }).then((responseText) => {
                    const myObj = JSON.parse(responseText)
                    if(myObj["tokens"]){
                        setNumberOfTokens(myObj["tokens"].length);
                        console.log("token length: " + myObj["tokens"].length)
                        setUnlimitedSlots(myObj["wallet"]["0"]["fields"]["unlimited_slots"])
                    }
                    setAllTokenData(myObj);
                    setTokensLoading(false);
                });
        });
    }

    function getCreateCoinTile(){
        return(
            <LinkButton className="tokenhub-token-slot-button" to='/Builder'>
                <div className="tokenhub-create-grey-circle" onClick={() => history.push("/Builder")}>
                    <img src={StepThreeIconInactive} className='tokenhub-create-token-icon' onClick={() => history.push("/Builder")}/>
                </div>
                <span className="tokenhub-create-token-span">+ Create</span>
            </LinkButton>
        )
    }

    function getCreateCoinTiles(){
        const numberOfTiles = 3 - numberOfTokens;
        if (numberOfTiles <= 0 && unlimitedSlots==1){
            numberOfTiles = 1;
        }
        const tilesHTML=[];
        for (let i = 0; i < numberOfTiles; i++){
            tilesHTML.push(getCreateCoinTile());
        }
        return (
            <>{tilesHTML}</>
        );
    }



    function getUserTokenTile(index){
        //let coinImage = allTokenData["tokens"][index.toString()]["fields"]["logo"].substring(0, 21) + "," + allTokenData["tokens"][index.toString()]["fields"]["logo"].substring(22);
        let coinImage = allTokenData["tokens"][index.toString()]["fields"]["logo"]
        var blockchain="";
        /*if (allTokenData["tokens"][index.toString()]["fields"]["chainid"] == 97){
            blockchain="BSC Testnet"
        }*/
        blockchain = Constants['chainNames'][allTokenData["tokens"][index.toString()]["fields"]["chainid"]];
        function tokenTileClicked(index){
            props.setTokenData(allTokenData["tokens"][index.toString()]["fields"])
            history.push("/TokenhubTokenView");
        }

        return(
            <div className="tokenhub-token-slot" onClick={() => tokenTileClicked(index)}>
                <span className="tokenhub-usertoken-type">{allTokenData["tokens"][index.toString()]["fields"]["token_type"]}</span>
                <div className='tokenhub-usertoken-icon-wrapper'>
                    <img src={coinImage} className='tokenhub-usertoken-icon'/>
                </div>
                <span className='tokenhub-usertoken-name'>{allTokenData["tokens"][index.toString()]["fields"]["name"]}<span className='tokenhub-usertoken-ticker'> #{allTokenData["tokens"][index.toString()]["fields"]["symbol"]}</span></span>
                <span className='tokenhub-usertoken-blockchain'>Blockchain: {blockchain}</span>
                <div className='tokenhub-usertoken-manage-token'>
                    <span className='tokenhub-usertoken-manage-token-span'>Manage token</span>
                </div>
            </div>
        )
    }

    function getUserTokenTiles(){
        const numberOfTiles = numberOfTokens;
        const tilesHTML=[];
        for (let i = 0; i < numberOfTiles; i++){
            tilesHTML.push(getUserTokenTile(i));
        }
        return (
            <>{tilesHTML}</>
        );
    }

    function getBuyUnlimitedSlotsTile(){
        /*return(
            <div className="tokenhub-token-slot">
                <div className="tokenhub-create-grey-circle">
                    <img src={StepThreeIconInactive} className='tokenhub-create-token-icon'/>
                </div>
                <span className="tokenhub-create-token-span">Make a one time payment <br></br>and get unlimited slots</span>
                <button className='tokenhub-buy-unlimited-slots-button'>Buy unlimited slots</button>
            </div>
        )*/
    }

    function getLoadingAnimation(){
        return(
            <div className='tokenhub-loading-animation-background'>
                <div className='tokenhub-loading-box'>
                    <div className="tokenhub-loading-lds-ring"><div></div><div></div><div></div><div></div></div>
                    <div className='tokenhub-loading-grey-circle'></div>
                    <img className='tokenhub-loading-icon' src={LoaderIcon}/>  
                </div>
            </div>
        )
    }

    function getTiles(){
        return(
            <div className="tokenhub-token-slots">
                {getUserTokenTiles()}
                {getCreateCoinTiles()}
                {unlimitedSlots==0 ? getBuyUnlimitedSlotsTile() : <></>}
            </div>
        )
    }

    return (
        <div className="tokenhub-background2">
            <span className="tokenhub-header">Token hub</span>
            <LinkButton className="tokenhub-create-new-token-button default-button" to='/Builder'>+ Create ERC20 token</LinkButton>
            <LinkButton className="tokenhub-create-new-token-button erc404 default-button" to='/Builder404'>+ Create ERC404 token</LinkButton>
            {tokensLoading ? getLoadingAnimation() : getTiles()}
            <div className='tokenhub-bottom-seperator'/>
            
        </div>

    )
}

export default Tokenhub;