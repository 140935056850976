import './BuilderStepTwo404.css';
import './styles.css'
import TokenikiLogo from '../img/logo_tokeniki.svg'
import React, {useEffect} from "react"
import WarningIcon from '../img/icons/warning.svg'

const BuilderStepTwo = (props) => {

    useEffect(() => {
        setNumberTyped(props.initialSupply)
        document.getElementById('initial-supply-input').value = props.initialSupply;
    }, [props.initialSupply])


    const [errorText, setErrorText] = React.useState("");
    const [initSupplyError, setInitSupplyError] = React.useState(false);
    const [numberTyped, setNumberTyped] = React.useState(props.initialSupply);

    React.useEffect(() => {
        setNumberTyped(numberTyped.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ")) //najpierw usuwa wszystkie nie cyfry potem daje seperatory z spacji w stylu 12 500 000
        document.getElementById('initial-supply-input').value = numberTyped;
    }, [numberTyped]);


    function backButtonClicked(event){
        props.moveToPreviousStep(true);
        props.stepTwoActive(false);
        props.stepTwoWasActive(true);
        props.scrollToTop();
    }

    function nextButtonClicked(event){
        const numInit = document.getElementById('initial-supply-input').value;
        const numNoSpaces=numInit.replace(/ /g, '')
        let error=false;
        setInitSupplyError(false);
        if (document.getElementById('initial-supply-input').value == "" || !Number.isInteger(Number(numNoSpaces))){
            setErrorText("The initial supply must be a whole number");
            setInitSupplyError(true);
            error = true;
        }
        if(error){
            return;
        }

        props.moveToNextStep(true);
        props.stepTwoActive(false);
        props.setStepThreeWasActive(true);
        props.setInitialSupply(Number(numNoSpaces));
        props.setMaximumSupply(Number(numNoSpaces));
        props.scrollToTop();
    }

    function getBottomErrorText(){
        if(errorText!==""){
            return(
                <div className='step-one-bottom-error'>
                    <img src={WarningIcon} className="step-one-error-warning-icon" alt="error"/>
                    <span className='step-one-error-text'>{errorText}</span>
                </div>
                
            )
        }
    }

    return (
        <div className="step-two-background">
            <span className="step-two-tokenomics-span h1">ERC-404</span>
            <span className="step-two-header">Step 2</span>
            <span className="step-two-tokenomics-span">Tokenomics</span>

            <div className="step-two-input-box-div-wide">
                <span className="step-box-input-box-description">Supply</span>
                <input type="text" autoComplete="off" className="step-two-input-box-wide" id="initial-supply-input" name="fname" placeholder="Initial supply of available tokens which will be put in your wallet" onChange={(e) => setNumberTyped(e.target.value)}></input>
            </div>
            {getBottomErrorText()}
            <button className="step-two-next-button default-button" onClick={nextButtonClicked}>
                Next
            </button>
            <button className="step-two-back-button default-button-secondary" onClick={backButtonClicked}>
                Back
            </button>

        </div>

    )
}

export default BuilderStepTwo;