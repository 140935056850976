import './BuilderStepFour404.css';
import './styles.css'
import CoinLogo from '../img/coinLogos/SWPL.png'
import React, {useEffect, useState} from "react"
import StepOneIcon from '../img/cube1.png';
import Constants from '../constants.json'
import { ethers } from "ethers";
import StepFourIcon from '../img/cube4.png';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
  } from "react-router-dom";
import image from '../img/blockfactory-placeholder.json';
import WarningIcon from '../img/icons/warning.svg'
import BurnIcon from '../img/icons/burn.svg'
import MintIcon from '../img/icons/mint.svg'
import BlacklistIcon from '../img/icons/blacklist.svg'
import WhaleProtectionIcon from '../img/icons/whaleprotection.svg'
import PauseIcon from '../img/icons/pause.svg'
import SnapshotIcon from '../img/icons/snapshotblue.svg'
import TokenRecoverIcon from '../img/icons/tokenrecover.svg'
import FeeIcon from '../img/icons/fee.svg'
import CopyrightIcon from '../img/icons/copyright.svg'
import EditIcon from '../img/icons/edit.svg'
import CheckmarkIcon from '../img/icons/checkmark_blue.svg'

const BuilderStepFour = (props) => {

    const placeholder_image = image['placeholder_image']


    const tokenName = props.tokenName;
    const symbol = props.symbol;
    const decimals = props.decimals;
    const description = props.description;
    const initialSupply = props.initialSupply;
    const maximumSupply = props.maximumSupply;
    const transactionFeeSelected = props.transactionFeeSelected;
    const burnTokensSelected = props.burnTokensSelected;
    const mintTokensSelected = props.mintTokensSelected;
    const noCopyrightsSelected = props.noCopyrightsSelected;
    const postEditableSmartContractSelected = props.postEditableSmartContractSelected;
    const blockchainName = props.blockchainName;
    const blacklistSelected = props.blacklistSelected;
    const recoverySelected = props.recoverySelected;
    const protectionSelected = props.protectionSelected;
    const pausableSelected = props.pausableSelected;
    const changeNameSelected = props.changeNameSelected;
    const coinLogo = props.coinLogo || placeholder_image;
    const burnFeeValue = props.burnFeeValue;
    const lpFeeValue = props.lpFeeValue;
    const transferFeeValue = props.transferFeeValue;
    const feeWallet = props.feeWallet;
    const swapRouter = props.swapRouter;
    const priceMultiplier = props.priceMultiplier

    const [walletConnected, setWalletConnected] = React.useState(props.walletConnected);
    const [isChainCorrect, setIsChainCorrect] = React.useState(false);
    let sum = 0;
    let sumCrypto = 0;
    let sumWithoutDiscount = 0;

    const [promoCode, setPromoCode] = useState('');
    const [discount, setDiscount] = useState(0);
    
    useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get('promocode');
      setPromoCode(code);
    
      if (code) {
        fetch(`${Constants['database']}/builder/validate_promocode/?code=${code}`)
            .then(response => response.json())
            .then(data => setDiscount(data.discount_in_percentage / 100))
            .catch(error => {
                console.error('Error:', error)
                setDiscount(0)});
        if(Constants['chainData'][blockchainName]['testnet'] === 'yes') {
            setPromoCode('');
        }
      }
    }, []);
    

    function backButtonClicked(event){
        props.moveToPreviousStep(true);
        props.stepFourActive(false);
        props.scrollToTop();
    }
    useEffect(() => {
        setWalletConnected(props.walletConnected);
        var chainID="";
        /*if (blockchainName == "BSC Testnet"){
            chainID="97"
        }
        else if (blockchainName == "Goerli"){
            chainID="5"
        }
        else if (blockchainName == "Mordor"){
            chainID="63"
        }
        else if (blockchainName == "Sepolia"){
            chainID="11155111"
        }*/

        const checkChain = async () => {
            chainID=Constants["chainData"][blockchainName]['chainID']
            const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            const walletChainId = await provider.getNetwork()
            console.log("wallet chain id: " + walletChainId.chainId);
            if (walletChainId.chainId != chainID){
                setIsChainCorrect(false);
                console.log("wallet chain id: " + walletChainId.chainId + ", chainid: " + chainID);
            }
            else{
                setIsChainCorrect(true);
            }
        }
        checkChain()
        
    }, [props.walletConnected])

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
      }

    const delayFetch = (url, options) =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(fetch(url, options));
        }, options.delay);
      });

    async function getBytecode(){
        var mintable = "0";
        if(mintTokensSelected){mintable="1"};
        var burnable = "0";
        if(burnTokensSelected){burnable="1"};
        var blacklist = "0";
        if(blacklistSelected){blacklist="1"};
        var recovery = "0";
        if(recoverySelected){recovery="1"};
        var protection = "0";
        if(protectionSelected){protection="1"};
        var changeable_name = "0";
        if(changeNameSelected){changeable_name = "1"};
        var pausable = "0";
        if(pausableSelected){pausable = "1"};
        var noCopright = "0";
        if(noCopyrightsSelected){noCopright = "1"};
        var fee = "0";
        if(transactionFeeSelected){fee = "1"};

        var feeSpecs = 0;
        if (fee != 0){
            feeSpecs=feeWallet + "," + transferFeeValue + "," + burnFeeValue + "," + lpFeeValue + "," + swapRouter + ",0,1,1";
        }

        /*console.log(Constants['database'] + '/builder/tokenparameters/?decimals='+ decimals +'&supplyparameter=' + maximumSupply + '&mintable=' + mintable + '&burnable=' + burnable + 
        '&protection=' + protection + '&blacklist=' + blacklist + '&recovery=' + recovery + '&pausable=0&snapshots=0&name='+ tokenName + '&ticker=' + symbol);
        const response = await fetch(Constants['database'] + '/builder/tokenparameters/?decimals='+ decimals +'&supply=' + maximumSupply + '&mintable=' + mintable + '&burnable=' + burnable + 
            '&protection=' + protection + '&blacklist=' + blacklist + '&recovery=' + recovery + '&pausable=0&snapshots=0&name='+ tokenName + '&ticker=' + symbol);*/
        console.log(Constants['database'] + '/builder/tokenparameters/?decimals='+ decimals +'&supply=' + maximumSupply + '&mintable=' + mintable + '&burnable=' + burnable + 
            '&pausable=' + pausable + '&changeable_name=' + changeable_name + '&name=' + tokenName + '&ticker=' + symbol + '&blacklist=' + blacklist + '&recovery=' + recovery + '&protection=' + protection +
            '&no_copyrights=' + noCopright + '&fee=' + fee + '&fee_specs=' + feeSpecs + '&is_404=true');
        const response = await fetch(Constants['database'] + '/builder/tokenparameters/?decimals='+ decimals +'&supply=' + maximumSupply + '&mintable=' + mintable + '&burnable=' + burnable + 
            '&pausable=' + pausable + '&changeable_name=' + changeable_name + '&name=' + tokenName + '&ticker=' + symbol + '&blacklist=' + blacklist + '&recovery=' + recovery + '&protection=' + protection +
            '&no_copyrights=' + noCopright + '&fee=' + fee + '&fee_specs=' + feeSpecs + '&is_404=true');
        const bytecode = await response.text();
        if (!response.ok){
            props.setShowErrorOverlay(true);
            return;
        }
        console.log(bytecode);
        var bytecodeJSON = JSON.parse(bytecode);
        console.log(bytecodeJSON);

        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();

        console.log(await signer.getAddress());
        const address = signer.getAddress;

        var chainID="";
        /*if (blockchainName == "BSC Testnet"){
            chainID="0x97"
        }
        else if (blockchainName == "Goerli"){
            chainID="0x05"
        }
        else if (blockchainName == "Mordor"){
            chainID="0x63"
        }
        else if (blockchainName == "Sepolia"){
            chainID="0x11155111"
        }*/
        chainID = "0x" + Constants["chainData"][blockchainName]['chainID']

        let zeroesToAdd = 18;
        if(Number.isInteger(sumCrypto)){
            zeroesToAdd = 18;
        }
        else{
            zeroesToAdd = 18 - sumCrypto.toString().split('.')[1].length;
        }
        
        let transferValue=sumCrypto.toString();
        transferValue = transferValue.split('.').join("");
        for (let i = zeroesToAdd; i>0; i--){
            transferValue = transferValue + "0";
        }
        console.log("transfer value:" + transferValue);
        var params = {
            //nonce: "0x00",
            from: await signer.getAddress(),
            value: "0x00",
            data: "0x" + bytecodeJSON.byteCode,
            chainId: chainID,
        }
        console.log(params.data);


        signer.estimateGas(params).then((gas) => {
            console.log("gas: " + gas);
            console.log("transfer value: " + transferValue);
            let gaslimit = parseInt(gas) + parseInt(0.17 * gas);

            var params2 = {
                gasLimit: gaslimit,
                // nonce: "0x00",
                from: signer.getAddress(),
                value: transferValue,
                data: "0x" + bytecodeJSON.byteCode,
                chainId: chainID,
            }

            signer.sendTransaction(params2).then((transaction) => {
                props.setShowOverlay(true);
                //let coinImage = coinLogo.substring(0, 21) + "p" + coinLogo.substring(22);
                let coinImage = coinLogo;
                
                console.dir(transaction);
                var contractAddress = transaction.creates;
                var transactionHash = transaction.hash;
                var address = params.from;
                window.ethereum.request({
                    method: 'wallet_watchAsset',
                    params: {
                      type: 'ERC20', // Initially only supports ERC20, but eventually more!
                      options: {
                        address: contractAddress, // The address that the token is at.
                        symbol: symbol, // A ticker symbol or shorthand, up to 5 chars.
                        decimals: decimals, // The number of decimals in the token
                      },
                    },
                });
    
                console.log(Constants['database'] + '/builder/verify_404/?decimals='+ decimals +'&supply=' + maximumSupply + '&mintable=' + mintable + '&burnable=' + burnable + 
                    '&pausable=' + pausable + '&changeable_name=' + changeable_name + '&name=' + tokenName + '&ticker=' + symbol + '&blacklist=' + blacklist + '&recovery=' + recovery + '&protection=' + protection +
                    '&no_copyrights=' + noCopright + '&fee=' + fee + '&fee_specs=' + feeSpecs + 
                    '&contract=' + contractAddress + '&chainid=' + chainID.substring(2) + '&code=' + promoCode + '&hash=' + transactionHash + '&token_info=' + 'coinLogo' + "," + description + ',Website,twitter,telegram,othermedias' + 
                    " address = " + signer.getAddress());
                    delayFetch(Constants['database'] + '/builder/verify_404/', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            decimals: decimals.toString(),
                            supply: maximumSupply.toString(),
                            mintable: "" + mintable, 
                            burnable: "" + burnable,
                            pausable: "" + pausable,
                            changeable_name: "" + changeable_name,
                            name: "" + tokenName,
                            ticker: "" + symbol,
                            blacklist: "" + blacklist,
                            recovery: "" + recovery,
                            protection: "" + protection,
                            no_copyrights: "" + noCopright,
                            fee: "" + fee,
                            fee_specs: "" + feeSpecs,
                            contract: "" + contractAddress,
                            chainid: "" + chainID.substring(2),
                            code: "" + promoCode,
                            hash: "" + transactionHash,
                            //token_info: coinImage + "," + description + ",Website,twitter,telegram,othermedias,test"
                            logo: coinImage,
                            description: description,
                            website: "",
                            twitter: "",
                            telegram: "",
                            other_media: "",
                            address: address
                        })
                    }).then(res => res.text())
                        .then(res => {
                            
                            console.log(res);
                            props.setShowOverlay(false);
                            props.history.push("/"); 
                            //TODO add error handling
                        });
    
                    //}).then(res => console.log(res))
            });
        });




    }

    function features(){
        const freeFeature=burnTokensSelected||blacklistSelected||pausableSelected||changeNameSelected
        const paidFeature=noCopyrightsSelected||postEditableSmartContractSelected||transactionFeeSelected||mintTokensSelected||recoverySelected||protectionSelected

        if (freeFeature&&paidFeature){
            return(
                <>
                    <span className="step-four-free-feature-header">Free</span>  
                    {burnTokensSelected?feature(BurnIcon, "Burn tokens","Burn tokens after transaction to make it deflationary",0):<></>}
                    {changeNameSelected?feature(EditIcon, "Changeable name and ticker","Ability to change token name and ticker after contract deployment",0):<></>}
                    {pausableSelected?feature(PauseIcon, "Pausable","Pausable transfers and allowances",0):<></>}
                    {blacklistSelected?feature(BlacklistIcon, "Blacklist address","Prevent addresses from interacting with your token",0):<></>}
                    <span className="step-four-free-feature-header">Paid</span>
                    <div className='step-four-empty-div'></div>
                    {transactionFeeSelected?feature(FeeIcon, "Transaction Fee","Charge fee for every transaction made with your token",0):<></>}
                    {mintTokensSelected?feature(MintIcon, "Mint tokens","Lets you mint additional tokens",0):<></>}
                    {recoverySelected?feature(TokenRecoverIcon, "Token recover","Lets you withdraw other tokens sent to the contract",0):<></>}
                    {protectionSelected?feature(WhaleProtectionIcon, "Whale protection","Set limits on how many tokens an address can have",0):<></>}
                    {noCopyrightsSelected?feature(CopyrightIcon, "No copyrights","Remove Blockfactory copyrights from smart contract",0.3):<></>}
                    {true?feature(CheckmarkIcon, "Base ERC404","Base price for ERC-404 token",0.3):<></>}
                    {/*postEditableSmartContractSelected?feature("Post-editable smart contract","Let you edit smart contract via Blockfactory after deploy",0.2):<></>*/}
                </>
            )
        }
        else if(freeFeature){
            return(
                <>
                    <span className="step-four-free-feature-header">Free</span>  
                    {burnTokensSelected?feature(BurnIcon, "Burn tokens","Burn tokens after transaction to make it deflationary",0):<></>}
                    {changeNameSelected?feature(EditIcon, "Changeable name and ticker","Ability to change token name and ticker after contract deployment",0):<></>}
                    {pausableSelected?feature(PauseIcon, "Pausable","Pausable transfers and allowances",0):<></>}
                    {blacklistSelected?feature(BlacklistIcon, "Blacklist address","Prevent addresses from interacting with your token",0):<></>}
                    <div className='step-four-empty-div'></div>
                    <span className="step-four-free-feature-header">Paid</span>
                    {true?feature(CheckmarkIcon, "Base ERC404","Base price for ERC-404 token",0.3):<></>}
                </>
            )
        }
        else if(paidFeature){
            return(
                <>
                    <span className="step-four-free-feature-header">Paid</span>
                    {transactionFeeSelected?feature(FeeIcon, "Transaction Fee","Charge fee for every transaction made with your token",0):<></>}
                    {mintTokensSelected?feature(MintIcon, "Mint tokens","Lets you mint additional tokens",0):<></>}
                    {recoverySelected?feature(TokenRecoverIcon, "Token recover","Lets you withdraw other tokens sent to the contract",0):<></>}
                    {protectionSelected?feature(WhaleProtectionIcon, "Whale protection","Set limits on how many tokens an address can have",0):<></>}
                    {noCopyrightsSelected?feature(CopyrightIcon, "No copyrights","Remove Blockfactory copyrights from smart contract",0.3):<></>}
                    {true?feature(CheckmarkIcon, "Base ERC404","Base price for ERC-404 token",0.3):<></>}
                    {/*postEditableSmartContractSelected?feature("Post-editable smart contract","Let you edit smart contract via Blockfactory after deploy",0.2):<></>*/}
                </>
            )
        }
        else{
            return(
                <>
                    <span className="step-four-free-feature-header">No features selected</span>  
                    {true?feature(CheckmarkIcon, "Base ERC404","Base price for ERC-404 token",0.3):<></>}
                </>

            )
        }
    }

    function feature(icon, title, description, price){
        let FeaturePrice = parseFloat((Constants["feature prices"][title] * (1 - discount)).toFixed(2))
        let FeaturePriceWithoutDiscount = parseFloat((Constants["feature prices"][title]).toFixed(2))
        let FeaturePriceInCrypto = parseFloat((Constants["feature prices"][title] * priceMultiplier * (1 - discount)).toPrecision(4))
        if (title != "Base ERC404"){
        console.log(FeaturePriceInCrypto)
        sumWithoutDiscount = parseFloat(sumWithoutDiscount) + parseFloat(FeaturePriceWithoutDiscount)
        sum = sum + FeaturePrice;
        sumCrypto = sumCrypto + FeaturePriceInCrypto;
        console.log(sumCrypto)
        }
        return (
            <div className='step-four-feature-div'>
                <div className='step-four-feature-circle'>
                <img src={icon} className='step-four-feature-icon-404'/>
                </div>
                <span className='step-four-feature-title'>{title}</span>
                <span className='step-four-feature-description'>{description}</span>
                {FeaturePrice!=0 ? <span className='step-four-feature-price'>${FeaturePrice.toFixed(2)}</span>:<></>}
            </div>
        )
    }

    function toPay(){
        sum = sum + parseFloat((Constants["feature prices"]["Base ERC404"]))
        sum = sum.toPrecision(4)
        sumWithoutDiscount = parseFloat(parseFloat(sumWithoutDiscount) + Constants["feature prices"]["Base ERC404"] + 299).toFixed(2)
        sumCrypto = sumCrypto + parseFloat((Constants["feature prices"]["Base ERC404"]) * priceMultiplier)
        sumCrypto = sumCrypto.toPrecision(4)
        console.log(sumCrypto)
        /*var sum =0;
        if (noCopyrightsSelected){sum=0.3}
        if (postEditableSmartContractSelected){sum=sum+0.2}*/
        if (sum!=0){
            return(
                <div className='step-four-to-pay-div'>
                    <span className='step-four-to-pay-text'>To pay </span>
                    <span className='step-four-to-pay-text fee'>(Transaction fee not included!) </span>
                    <span className='step-four-to-pay-text currency'>Currency: {Constants["tickers"][blockchainName]} </span>
                    <span className='step-four-to-pay-amount'>${sum} </span>
                    <span className='step-four-to-pay-amount saved'>Saved: ${parseFloat(sumWithoutDiscount - sum).toFixed(2)} </span>
                </div>
            )
        }
    }

    function payButton(){
        if (!walletConnected){
            return(<button className="step-four-pay-button-greyed-out">Please connect your wallet to continue</button>)
        }
        else if(isChainCorrect){
            return(<button className="step-four-pay-button default-button" onClick={getBytecode}>Pay & create token</button>)
        }
        else{
            return(<button className="step-four-pay-button default-button-alert" onClick={changeNetwork}>Click to change your metamask network</button>)

        }
    }

    async function changeNetwork(){
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        if (Constants["chainData"][blockchainName]['metamaskDefault'] === 'yes'){
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{
                    chainId: Constants["chainData"][blockchainName]['chainIDHEX'],
                }]
            });
            setIsChainCorrect(true);
        }
        else{
            await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [{
                    chainId: Constants["chainData"][blockchainName]['chainIDHEX'],
                    rpcUrls: Constants["chainData"][blockchainName]['rpcUrls'],
                    chainName: Constants["chainData"][blockchainName]['chainName'],
                    nativeCurrency: {
                        name: Constants["chainData"][blockchainName]['nativeCurrency']['name'],
                        symbol: Constants["chainData"][blockchainName]['nativeCurrency']['symbol'],
                        decimals: parseInt(Constants["chainData"][blockchainName]['nativeCurrency']['decimals']),
                    },
                    blockExplorerUrls: Constants["chainData"][blockchainName]['blockExplorerUrls']
                }]
            });
            setIsChainCorrect(true);
        }


        
        /*if (blockchainName == "BSC Testnet"){
            await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [{
                    chainId: '0x61',
                    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
                    chainName: "Smart Chain - Testnet",
                    nativeCurrency: {
                        name: "BNB",
                        symbol: "BNB",
                        decimals: 18
                    },
                    blockExplorerUrls: ["https://testnet.bscscan.com/"]
                }]
            });
            setIsChainCorrect(true);
        }
        else if (blockchainName == "Goerli"){
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{
                    chainId: '0x5',
                }]
            });
            setIsChainCorrect(true);
        }
        else if (blockchainName == "Mordor"){
            await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [{
                    chainId: '0x3f',
                    rpcUrls: ["https://www.ethercluster.com/mordor"],
                    chainName: "Ethereum Classic Testnet Mordor",
                    nativeCurrency: {
                        name: "METC",
                        symbol: "METC",
                        decimals: 18
                    },
                    blockExplorerUrls: ["https://mordor.etherscan.io/"]
                }]
            });
            setIsChainCorrect(true);
        }
        else if (blockchainName == "Sepolia"){
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{
                    chainId: '0xaa36a7',
                }]
            });
            setIsChainCorrect(true);
        }*/
    }

    

    return (
        <div className="step-four-background" id="step-four-background">
            <span className="step-four-header">Step 4</span>
            <span className="step-four-summary-span">Summary</span>
            <div className='step-four-token-logo-wrapper'>
                <img src={coinLogo} className="step-four-token-logo"/>
            </div>
            <span>
                <span className='step-four-token-name'>{tokenName}</span>
                <span className='step-four-token-ticker'>#{symbol}</span>
            </span>
            <span className='step-four-blockchain'>Blockchain: {blockchainName}</span>
            <span className='step-four-description'>Description</span>
            <span className='step-four-description-text'>{description}</span>

            {/*<span className='step-four-description'>Website</span>
            <span className='step-four-description-text'>https://swaple.dex/</span>*/}

            <span className='step-four-tokenomics-text'>Tokenomics</span>

            <div className='step-four-tokenomics-grid'>
                <div className='step-four-tokenomic-grid-item'>
                    <span className='step-four-description'>Supply type</span>
                    <span className='step-four-description-text'>{mintTokensSelected ? "Flexible" : "Fixed"}</span>
                </div>
                <div className='step-four-tokenomic-grid-item'>
                    <span className='step-four-description'>Initial supply</span>
                    <span className='step-four-description-text'>{initialSupply}</span>
                </div>
                <div className='step-four-tokenomic-grid-item'>
                    <span className='step-four-description'>Maximum supply</span>
                    <span className='step-four-description-text'>{maximumSupply}</span>
                </div>
            </div>
            <br></br>
            
            <span className='step-four-tokenomics-text'>Features</span>
            {features()}
            {toPay()}


            <button className="step-four-back-button default-button-secondary" onClick={backButtonClicked}>
                Back
            </button>
            {//-walletConnected ? <button className="step-four-pay-button" onClick={getBytecode}>Pay & create token</button> :  <button className="step-four-pay-button-greyed-out">Please connect your wallet to continue</button>
            }
            {payButton()}
        </div>

    )
}

export default BuilderStepFour;