import './EditTokenPopup.css';
import './../styles.css';
import Constants from '../../constants.json'
import React, { useState, useEffect, useRef, useCallback } from "react";
import XIcon from '../../img/icons/x.svg';
import {useDropzone} from 'react-dropzone';
import DragImageIcon from '../../img/dragdropScreenshot.png';
import { ethers} from "ethers";

const EditTokenPopup = (props) => {
    const [tokenData,setTokenData] = React.useState(props.tokenData)
    const [coinLogo,setCoinLogo] = React.useState(props.coinLogo)    
    const [imageimage,setImageimage]=React.useState("");
    const [uploadedImage, setUploadedImage] = React.useState("");

    useEffect(() => {
        setTokenData(props.tokenData);
        /*setCoinLogo(tokenData["logo"].substring(0, 21) + "," + tokenData["logo"].substring(22));
        setUploadedImage(tokenData["logo"].substring(0, 21) + "," + tokenData["logo"].substring(22));*/
        setCoinLogo(tokenData["logo"])
        setUploadedImage(tokenData["logo"])
    }, [props.tokenData])

    async function setImageAsync(img){
        setUploadedImage(img);
    }

    function Preview(props) {
        const onDrop = useCallback((acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                console.log("test");
                const reader = new FileReader()
        
                reader.onabort = () => console.log('file reading was aborted')
                reader.onerror = () => console.log('file reading has failed')
                reader.onload = () => {
                    // Do whatever you want with the file contents
                    const binaryStr = reader.result
                    console.log(binaryStr)
                        setImageimage(binaryStr);
                        console.log("file read")
                    console.log("img:" + imageimage)
                }
                reader.readAsDataURL(file)
                console.log(reader);

                setFiles(acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })))  
            })
        }, [])


        const [files, setFiles] = useState([]);
        const {getRootProps, getInputProps} = useDropzone({
          accept: {
            'image/*': []
          },
          maxFiles:1,
          onDrop});

        useEffect(() => {
            if (files.length){
                setImageAsync(files[0].preview)
            }
        }, [files.length]);
        useEffect(() => {
            // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
            return () => files.forEach(file => URL.revokeObjectURL(file.preview));
            
        }, []);
  
        if (files.length){
            return (
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <div className='edit-token-popup-logo-image-wrapper'>
                        <img className="edit-token-popup-logo-image" alt="drag image icon" src={files[0].preview} onLoad={() => { URL.revokeObjectURL(files[0].preview) }}/>
                    </div>
                </div>
            )
        }

        else{
            console.log("img:" + uploadedImage);
            return (
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <div className='edit-token-popup-logo-image-wrapper'>
                        {uploadedImage === "" ? <img src={DragImageIcon} className="edit-token-popup-logo-image" alt="drag image icon"/> : <img src={uploadedImage} className="edit-token-popup-logo-image" alt="drag image icon"/>}
                        
                    </div>
                </div>
            )
        }
    }

    async function updateSocials(sig){
        let myObj = {...tokenData};
        let socialsChanged = false
        let requestParams = ""
        //let coinImage = imageimage.substring(0, 21) + "p" + imageimage.substring(22);
        /*if(coinImage !== tokenData['logo']){
            myObj['logo'] = coinImage
            socialsChanged = true
            requestParams = requestParams + "&logo=" + coinImage
        }*/
        if(document.getElementById("edit-token-popup-description-input").value !== tokenData['description']){
            myObj['description'] = document.getElementById("edit-token-popup-description-input").value
            socialsChanged = true
            requestParams = requestParams + "&description=" + document.getElementById("edit-token-popup-description-input").value
        }
        if(document.getElementById("edit-token-popup-website-input").value !== tokenData['website']){
            myObj['website'] = document.getElementById("edit-token-popup-website-input").value
            socialsChanged = true
            requestParams = requestParams + "&website=" + document.getElementById("edit-token-popup-website-input").value
        }
        if(document.getElementById("edit-token-popup-twitter-input").value !== tokenData['twitter']){
            myObj['twitter'] = document.getElementById("edit-token-popup-twitter-input").value
            socialsChanged = true
            requestParams = requestParams + "&twitter=" + document.getElementById("edit-token-popup-twitter-input").value
        }
        if(document.getElementById("edit-token-popup-telegram-input").value !== tokenData['telegram']){
            myObj['telegram'] = document.getElementById("edit-token-popup-telegram-input").value
            socialsChanged = true
            requestParams = requestParams + "&telegram=" + document.getElementById("edit-token-popup-telegram-input").value
        }
        if(document.getElementById("edit-token-popup-discord-input").value !== tokenData['other']){
            myObj['other'] = document.getElementById("edit-token-popup-discord-input").value
            socialsChanged = true
            requestParams = requestParams + "&other_media=" + document.getElementById("edit-token-popup-discord-input").value
        }

        if(socialsChanged){
            const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            setTokenData(myObj);
            props.setTokenData(myObj);
            signer.getAddress().then(address => {
                console.log("request: " + Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + requestParams)
                fetch(Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + requestParams)
                .then((response) => {
                    return response.text()}).then((responseText) => {
                        const myObj2 = JSON.parse(responseText)
                        console.log("response: " + responseText);
                        props.setShowEditPopup(false)
                    })
            })
        }
        if(imageimage !== tokenData['logo']){
            const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            myObj['logo'] = imageimage
            setTokenData(myObj);
            props.setTokenData(myObj);
            props.changeLogo(imageimage)
            signer.getAddress().then(address => {
                fetch(Constants['database'] + '/builder/edit_logo/', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        logo: imageimage,
                        signature: sig,
                        wallet: address,
                        token_contract: tokenData["token_address"],
                        chainid: tokenData["chainid"]
                    })
                }).then(res => res.text())
                    .then(res => {
                        console.log(res);
                        props.setShowEditPopup(false)
                        //TODO add error handling
                    });
                })
            }
    }

    function saveButtonClicked(){
        props.authorizeThenExecute(updateSocials)
    }

    function cancelButtonClicked(){
        props.setShowEditPopup(false)
    }

    return (
        <div className="edit-token-popup-background">
            <div className='edit-token-popup'>
                <span className='edit-token-popup-title'>Edit</span>
                <img src={XIcon} className="edit-token-popup-close-icon"  onClick={() => props.setShowEditPopup(false)}/>
                <svg className="edit-token-popup-divider" width="100%" height="1">
                    <rect width="100%" height="1" />
                </svg>
                <div className="edit-token-popup-body-wrapper">
                    <div className="edit-token-popup-body">
                        <div className='edit-token-popup-logo'>
                            <span className='edit-token-popup-logo-description'>Coin image</span>
                            <Preview />
                        </div>
                        <div className='input-default-with-description edit-token-popup-description'>
                            <span className='input-default-description'>Description</span>
                            <textarea className='input-default-description-input textarea-default edit-token-popup-description-input' id='edit-token-popup-description-input' defaultValue={tokenData['description']}></textarea>
                        </div>
                        <div className='input-default-with-description edit-token-popup-input' >
                            <span className='input-default-description'>Website</span>
                            <input className='input-default-description-input' id='edit-token-popup-website-input' defaultValue={tokenData['website']}></input>
                        </div>
                        <div className='input-default-with-description edit-token-popup-input'>
                            <span className='input-default-description'>Twitter</span>
                            <input className='input-default-description-input' id='edit-token-popup-twitter-input' defaultValue={tokenData['twitter']}></input>
                        </div>
                        <div className='input-default-with-description edit-token-popup-input'>
                            <span className='input-default-description'>Telegram</span>
                            <input className='input-default-description-input' id='edit-token-popup-telegram-input' defaultValue={tokenData['telegram']}></input>
                        </div>
                        <div className='input-default-with-description edit-token-popup-input'>
                            <span className='input-default-description'>Discord</span>
                            <input className='input-default-description-input' id='edit-token-popup-discord-input' defaultValue={tokenData['other']}></input>
                        </div>
                    </div>
                </div>
                <svg className="edit-token-popup-bottom-divider" width="100%" height="1">
                    <rect width="100%" height="1" />
                </svg>
                <button className='default-button-secondary edit-token-popup-cancel-button' onClick={() => cancelButtonClicked()}>Cancel</button>
                <button className='default-button edit-token-popup-save-button' onClick={() => saveButtonClicked()}>Save</button>
            </div>
        </div>
    )
}

export default EditTokenPopup;