import './BuilderSteps.css';
import StepOneIcon from '../img/cube1.png';
import StepOneIconInactive from '../img/cube2_disabled.png';
import StepTwoIcon from '../img/cube2.png';
import StepTwoIconInactive from '../img/cube2_disabled.png';
import StepThreeIcon from '../img/cube3.png';
import StepThreeIconInactive from '../img/cube3_disabled.png';
import StepFourIcon from '../img/cube4.png';
import StepFourIconInactive from '../img/cube4_disabled.png';
import React, { useState, useEffect, useRef } from "react";
import BuilderStepOne from './BuilderStepOne404.js'
import BuilderStepTwo from './BuilderStepTwo404.js'
import BuilderStepThree from './BuilderStepThree404.js'
import BuilderStepFour from './BuilderStepFour404.js'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
  } from "react-router-dom";

const BuilderSteps = (props) => {

    const [stepOneActive, setStepOneActive] = React.useState(true);
    const [stepTwoActive, setStepTwoActive] = React.useState(false);
    const [stepThreeActive, setStepThreeActive] = React.useState(false);
    const [stepFourActive, setStepFourActive] = React.useState(false);
    const [stepOneWasActive, setStepOneWasActive] = React.useState(true);
    const [stepTwoWasActive, setStepTwoWasActive] = React.useState(false);
    const [stepThreeWasActive, setStepThreeWasActive] = React.useState(false);
    const [stepFourWasActive, setStepFourWasActive] = React.useState(false);
    const [nextButtonOneClicked, setNextButtonOneClicked] = React.useState(false);
    const [coinLogo, setCoinLogo] = React.useState("");
    const [tokenName, setTokenName] = React.useState("");
    const [symbol, setSymbol] = React.useState("");
    const [decimals, setDecimals] = React.useState("");
    const [description, setDescription] = React.useState("")
    const [initialSupply, setInitialSupply] = React.useState("");
    const [maximumSupply, setMaximumSupply] = React.useState("");
    const [blockchainName, setBlockchainName] = React.useState("BSC Testnet");
    const [transactionFeeSelected, setTransactionFeeSelected] = React.useState(false);
    const [burnTokensSelected, setBurnTokensSelected] = React.useState(false);
    const [mintTokensSelected, setMintTokensSelected] = React.useState(false);
    const [noCopyrightsSelected, setNoCopyrightsSelected] = React.useState(false);
    const [postEditableSmartContractSelected, setPostEditableSmartContractSelected] = React.useState(false);
    const [blacklistSelected, setBlacklistSelected] = React.useState(false);
    const [recoverySelected, setRecoverySelected] = React.useState(false);
    const [protectionSelected, setProtectionSelected] = React.useState(false);
    const [pausableSelected, setPausableSelected] = React.useState(false);
    const [changeNameSelected, setChangeNameSelected] = React.useState(false);
    const [transferFeeValue, setTransferFeeValue] = React.useState(5);
    const [lpFeeValue, setlpFeeValue] = React.useState(0);
    const [burnFeeValue, setBurnFeeValue] = React.useState(5);
    const [feeWallet, setFeeWallet] = React.useState("");
    const [swapRouter, setSwapRouter] = React.useState("");
    const [priceMultiplier, setPriceMultiplier] = React.useState(0);

    const [contractAddress, setContractAddress] = React.useState("");
    const history = useHistory();
    const [walletConnected, setWalletConnected] = React.useState(props.walletConnected);


    useEffect(() => {
        props.setContractAddress(contractAddress);
    }, [contractAddress])
    useEffect(() => {
        setWalletConnected(props.walletConnected);
    }, [props.walletConnected])
    useEffect(() => {
        if(stepOneActive){
            setStepTwoActive(false);
            setStepThreeActive(false);
            setStepFourActive(false);
            setStepTwoWasActive(false);
            setStepThreeWasActive(false);
            setStepFourWasActive(false);
        }
    }, [stepOneActive]) 
    useEffect(() => {
        if(stepTwoActive){
            setStepOneActive(false);
            setStepThreeActive(false);
            setStepFourActive(false);
            setStepThreeWasActive(false);
            setStepFourWasActive(false);
        }

    }, [stepTwoActive]) 
    useEffect(() => {
        if(stepThreeActive){
            setStepTwoActive(false);
            setStepOneActive(false);
            setStepFourActive(false);
            setStepFourWasActive(false);
        }
    }, [stepThreeActive]) 
    useEffect(() => {
        if(stepFourActive){
            setStepTwoActive(false);
            setStepThreeActive(false);
            setStepOneActive(false);
        }
    }, [stepFourActive]) 

    function scrollToTop(){
        document.getElementById('builder-input-background').scrollTop = 0;
    }

    function passUpAddress(address){
        /*props.setContractAddress(address);
        props.setTokenName(tokenName);
        props.setSymbol(symbol);
        props.setDecimals(decimals);
        props.setMaximumSupply(maximumSupply);
        props.setBlockchainName(blockchainName);
        props.setBurnTokensSelected(burnTokensSelected);
        props.setMintTokensSelected(mintTokensSelected);
        props.setBlacklistSelected(blacklistSelected);
        props.setRecoverySelected(recoverySelected);
        props.setProtectionSelected(protectionSelected);*/
    }

    function activeStepComponent(icon, title, description){
        return(
            <div className="builder-step">
                <img src={icon} className="step-icon" alt="Step icon"/>
                <span className="builder-step-title-active">{title}</span>
                <span className="builder-step-description-active">{description}</span>
                <svg className="active-step-highlight" width="100%" height="3">
                    <rect width="100%" height="3" />
                </svg>
            </div>
        )
    }

    function inactiveStepComponent(icon, title, description){
        return(
            <div className="builder-step">
                <img src={icon} className="step-icon" alt="Step icon"/>
                <span className="builder-step-title-inactive">{title}</span>
                <span className="builder-step-description-inactive">{description}</span>
            </div>
        )
    }

    function wasActiveStepComponent(icon, title, description, changeTo){
        return(
            <div className="builder-step builder-step-was-active" onClick={() => changeTo(true)}>
                <img src={icon} className="step-icon" alt="Step icon"/>
                <span className="builder-step-title-active">{title}</span>
                <span className="builder-step-description-active">{description}</span>
            </div>
        )
    }

    function getStepForBar(stepActive, stepWasActive, icon, iconInactive, title, description, jumpTo){
        if(stepActive){
            return activeStepComponent(icon, title, description)
            
        }
        else if(stepWasActive){
            return wasActiveStepComponent(icon, title, description, jumpTo)
            
        }
        else{
            return inactiveStepComponent(iconInactive, title, description,)
        }
    }


    function renderCurrentStep(){
        if (stepOneActive){
            return(<BuilderStepOne moveToNextStep={setStepTwoActive} stepOneActive={setStepOneActive} tokenName={tokenName} setTokenName={setTokenName} symbol={symbol} setSymbol={setSymbol} 
                decimals={decimals} setDecimals={setDecimals} description={description} setDescription={setDescription} blockchainName={blockchainName} setBlockchainName={setBlockchainName}
                coinLogo={coinLogo} setCoinLogo={setCoinLogo} stepOneWasActive={stepOneWasActive} setStepTwoWasActive={setStepTwoWasActive} scrollToTop={scrollToTop}/>)
        }
        else if (stepTwoActive){
            return(<BuilderStepTwo moveToPreviousStep={setStepOneActive} stepTwoActive={setStepTwoActive} moveToNextStep={setStepThreeActive} initialSupply={initialSupply} setInitialSupply={setInitialSupply}
                maximumSupply={maximumSupply} setMaximumSupply={setMaximumSupply} setStepThreeWasActive={setStepThreeWasActive} scrollToTop={scrollToTop}/>)
            }
        else if (stepThreeActive){
            return(<BuilderStepThree moveToPreviousStep={setStepTwoActive} stepThreeActive={setStepThreeActive} moveToNextStep={setStepFourActive} transactionFeeSelected={transactionFeeSelected}
                setTransactionFeeSelected={setTransactionFeeSelected} burnTokensSelected={burnTokensSelected} setBurnTokensSelected={setBurnTokensSelected} 
                mintTokensSelected={mintTokensSelected} setMintTokensSelected={setMintTokensSelected} noCopyrightsSelected={noCopyrightsSelected} setNoCopyrightsSelected={setNoCopyrightsSelected}
                postEditableSmartContractSelected={postEditableSmartContractSelected} setPostEditableSmartContractSelected={setPostEditableSmartContractSelected}
                blacklistSelected={blacklistSelected} setBlacklistSelected={setBlacklistSelected} recoverySelected={recoverySelected} setRecoverySelected={setRecoverySelected}
                protectionSelected={protectionSelected} setProtectionSelected={setProtectionSelected} changeNameSelected={changeNameSelected} setChangeNameSelected={setChangeNameSelected} 
                pausableSelected={pausableSelected} setPausableSelected={setPausableSelected} blockchainName={blockchainName} walletConnected={walletConnected} setPriceMultiplier={setPriceMultiplier}
                transferFeeValue={transferFeeValue} setTransferFeeValue={setTransferFeeValue} burnFeeValue={burnFeeValue} setBurnFeeValue={setBurnFeeValue} setStepFourWasActive={setStepFourWasActive}
                lpFeeValue={lpFeeValue} setlpFeeValue={setlpFeeValue} feeWallet={feeWallet} setFeeWallet={setFeeWallet} swapRouter={swapRouter} setSwapRouter={setSwapRouter} scrollToTop={scrollToTop}/>)
        }
        else if (stepFourActive){
            return(<BuilderStepFour moveToPreviousStep={setStepThreeActive} stepFourActive={setStepFourActive} tokenName={tokenName} symbol={symbol} decimals={decimals} description={description}
                initialSupply={initialSupply} maximumSupply={maximumSupply} transactionFeeSelected={transactionFeeSelected} burnTokensSelected={burnTokensSelected}
                mintTokensSelected={mintTokensSelected} noCopyrightsSelected={noCopyrightsSelected} postEditableSmartContractSelected={postEditableSmartContractSelected} blockchainName={blockchainName}
                blacklistSelected={blacklistSelected} recoverySelected={recoverySelected} protectionSelected={protectionSelected} pausableSelected={pausableSelected} changeNameSelected={changeNameSelected} coinLogo={coinLogo}
                contractAddress={contractAddress} setContractAddress={setContractAddress} history={history} passUpAddress={passUpAddress} walletConnected={walletConnected} setWalletConnected={setWalletConnected}
                setShowOverlay={props.setShowOverlay} transferFeeValue={transferFeeValue} setTransferFeeValue={setTransferFeeValue} burnFeeValue={burnFeeValue} setBurnFeeValue={setBurnFeeValue} priceMultiplier={priceMultiplier}
                lpFeeValue={lpFeeValue} setlpFeeValue={setlpFeeValue} feeWallet={feeWallet} setFeeWallet={setFeeWallet} swapRouter={swapRouter} setSwapRouter={setSwapRouter} setShowErrorOverlay={props.setShowErrorOverlay} scrollToTop={scrollToTop}/>)
        }
    }

    return (
        <div className="builder-top-level">
            <div className="buildersteps-background">
                <svg className="top-divider" width="100%" height="1">
                    <rect width="100%" height="1" />
                </svg>
                <div className="builder-steps">
                    {getStepForBar(stepOneActive, stepOneWasActive, StepOneIcon, StepOneIconInactive, "Step 1", "Information", setStepOneActive)}
                    {getStepForBar(stepTwoActive, stepTwoWasActive, StepTwoIcon, StepTwoIconInactive, "Step 2", "Tokenomics", setStepTwoActive)}
                    {getStepForBar(stepThreeActive, stepThreeWasActive, StepThreeIcon, StepThreeIconInactive, "Step 3", "Features", setStepThreeActive)}
                    {getStepForBar(stepFourActive, stepFourWasActive, StepFourIcon, StepFourIconInactive, "Step 4", "Summary", setStepFourActive)}
                </div>
                <svg className="bottom-divider" width="100%" height="1">
                    <rect className="bottom-divider" width="100%" height="1" />
                </svg>
            </div>
            <div className="builder-input-background" id="builder-input-background">
                {renderCurrentStep()}
            </div>
                
        </div>


    )
}

export default BuilderSteps;