import logo from './logo.svg';
import './App.css';
import Header from './components/Header'
import BuilderSteps from './components/BuilderSteps'
import BuilderSteps404 from './components/BuilderSteps404'
import Tokeninfo from './components/Tokeninfo'
import Tokenhub from './components/tokenhub/Tokenhub'
import TokenhubTokenView from './components/tokenhub/TokenhubTokenView';
import WaitingOverlay from './components/WaitingOverlay';
import ErrorOverlay from './components/ErrorOverlay';
import MobileNoSupportPage from './components/MobileNoSupportPage';
import { useMediaQuery } from 'react-responsive'
import React, { useState, useEffect, useRef } from "react";
import { ethers } from "ethers";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";
import { clarity } from 'react-microsoft-clarity';

function App() {

  const [contractAddress, setContractAddress] = React.useState("");
  const [tokenName, setTokenName] = React.useState("");
  const [symbol, setSymbol] = React.useState("");
  const [decimals, setDecimals] = React.useState("");
  const [maximumSupply, setMaximumSupply] = React.useState("");
  const [blockchainName, setBlockchainName] = React.useState("");
  const [burnTokensSelected, setBurnTokensSelected] = React.useState(false);
  const [mintTokensSelected, setMintTokensSelected] = React.useState(false);
  const [blacklistSelected, setBlacklistSelected] = React.useState(false);
  const [recoverySelected, setRecoverySelected] = React.useState(false);
  const [protectionSelected, setProtectionSelected] = React.useState(false);
  const [tokenData, setTokenData] = React.useState({});
  const [walletConnected, setWalletConnected] = React.useState(false);
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [showErrorOverlay,setShowErrorOverlay] = React.useState(false);
  const history = useHistory();

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 882 })
    return isDesktop ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 882 })
    return isMobile ? children : null
  }

  useEffect(() => {
    clarity.init("ii5su93yyw");
}, [])

  return (
    <Router>
      <Switch>
      <Route path ="/TokenInfo">
        <div className="App">
          <Header walletConnected={walletConnected} setWalletConnected={setWalletConnected}/>
          <Tokeninfo contractAddress={contractAddress} setContractAddress={setContractAddress} tokenName={tokenName} symbol={symbol} decimals={decimals} maximumSupply={maximumSupply}
              blockchainName={blockchainName} burnTokensSelected = {burnTokensSelected} mintTokensSelected={mintTokensSelected} blacklistSelected={blacklistSelected}
              recoverySelected={recoverySelected} protectionSelected={protectionSelected}/>
        </div>
      </Route>
      <Route path ="/Builder">
        <div className="App">
          <Header walletConnected={walletConnected} setWalletConnected={setWalletConnected}/>
          <BuilderSteps contractAddress={contractAddress} setContractAddress={setContractAddress} setTokenName={setTokenName} setSymbol={setSymbol} setShowOverlay={setShowOverlay} setShowErrorOverlay={setShowErrorOverlay}
              setDecimals={setDecimals} setMaximumSupply={setMaximumSupply} setBlockchainName={setBlockchainName} setBurnTokensSelected={setBurnTokensSelected} setMintTokensSelected={setMintTokensSelected}
              walletConnected={walletConnected} setWalletConnected={setWalletConnected} setBlacklistSelected={setBlacklistSelected} setRecoverySelected={setRecoverySelected} setProtectionSelected={setProtectionSelected}/>
          {showOverlay ? <WaitingOverlay/> : <></>}
          {showErrorOverlay ? <ErrorOverlay setShowErrorOverlay={setShowErrorOverlay}/> : <></>}
        </div>
      </Route>
      <Route path ="/Builder404">
        <div className="App">
          <Header walletConnected={walletConnected} setWalletConnected={setWalletConnected}/>
          <BuilderSteps404 contractAddress={contractAddress} setContractAddress={setContractAddress} setTokenName={setTokenName} setSymbol={setSymbol} setShowOverlay={setShowOverlay} setShowErrorOverlay={setShowErrorOverlay}
              setDecimals={setDecimals} setMaximumSupply={setMaximumSupply} setBlockchainName={setBlockchainName} setBurnTokensSelected={setBurnTokensSelected} setMintTokensSelected={setMintTokensSelected}
              walletConnected={walletConnected} setWalletConnected={setWalletConnected} setBlacklistSelected={setBlacklistSelected} setRecoverySelected={setRecoverySelected} setProtectionSelected={setProtectionSelected}/>
          {showOverlay ? <WaitingOverlay/> : <></>}
          {showErrorOverlay ? <ErrorOverlay setShowErrorOverlay={setShowErrorOverlay}/> : <></>}
        </div>
      </Route>
      <Route path ="/TokenhubTokenView">
        <div className="App">
          <Header walletConnected={walletConnected} setWalletConnected={setWalletConnected}/>
          <TokenhubTokenView walletConnected={walletConnected} setWalletConnected={setWalletConnected} tokenData={tokenData}/>
        </div>
      </Route>
      <Route path ="/">
        <div className="App">
          <Desktop>
            <Header walletConnected={walletConnected} setWalletConnected={setWalletConnected}/>
            <Tokenhub walletConnected={walletConnected} setWalletConnected={setWalletConnected} setTokenData={setTokenData}/>
          </Desktop>
          <Mobile>
            <MobileNoSupportPage/>
          </Mobile>

        </div>
      </Route>

      </Switch>
    </Router>
  );
}

export default App;
