import "./BuilderStepOne404.css";
import "./styles.css";
import TextareaAutosize from "react-textarea-autosize";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import DragImageIcon from "../img/dragdropScreenshot.png";
import WarningIcon from "../img/icons/warning.svg";
import Constants from "../constants.json";
const defaultImage = "../image/blockfactory-placeholder.jpg";

function useComponentVisible(initialIsVisible) {
  // wykrywa klikniecia poza dropdownem zeby go zamknac wtedy
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

const BuilderStepOne = (props) => {
  const [blockchainName, setBlockchainName] = React.useState(
    props.blockchainName
  );
  const [blockchainLogo, setBlockchainLogo] = React.useState();
  const [showBlockchainDropdown, setShowBlockchainDropdown] =
    React.useState(false);
  const [errorText, setErrorText] = React.useState("");
  const [coinLogo, setCoinLogo] = React.useState();
  const [tokenNameError, setTokenNameError] = React.useState(false);
  const [symbolError, setSymbolError] = React.useState(false);
  const [decimalsError, setDecimalsError] = React.useState(false);
  const [descriptionError, setDescriptionError] = React.useState(false);
  const [uploadedImage, setUploadedImage] = React.useState("");
  const [decimalsTyped, setDecimalsTyped] = React.useState(props.decimals);

  const [imageimage, setImageimage] = React.useState("");

  useEffect(() => {
    setDecimalsTyped(decimalsTyped.toString().replace(/\D/g, "")); //usuwa wszystkie nie cyfry
    document.getElementById("decimals-input").value = decimalsTyped;
  }, [decimalsTyped]);

  useEffect(() => {
    document.getElementById("token-name-input").value = props.tokenName;
    //setShowBlockchainDropdown(false);
  }, [props.tokenName]);

  useEffect(() => {
    document.getElementById("symbol-input").value = props.symbol;
  }, [props.symbol]);

  useEffect(() => {
    document.getElementById("decimals-input").value = props.decimals;
  }, [props.decimals]);

  useEffect(() => {
    document.getElementById("description-input").value = props.description;
  }, [props.description]);

  useEffect(() => {
    setUploadedImage(props.coinLogo);
  }, [props.coinLogo]);

  useEffect(() => {
    setBlockchainName(props.blockchainName);
    setBlockchainLogo(
      require("../img/coinLogos/" +
        Constants["chainData"][blockchainName]["img"])
    );
  }, [props.blockchainName]);

  async function setImageAsync(img) {
    setUploadedImage(img);
  }

  const blockchainDropdownClicked = () => {
    if (showBlockchainDropdown) {
      setShowBlockchainDropdown(false);
    } else {
      setShowBlockchainDropdown(true);
    }
  };

  const minusButtonClicked = () => {
    if (isNaN(parseInt(document.getElementById("decimals-input").value))) {
      document.getElementById("decimals-input").value = 12;
    } else if (parseInt(document.getElementById("decimals-input").value) > 12) {
      document.getElementById("decimals-input").value =
        parseInt(document.getElementById("decimals-input").value) - 1;
    } else {
      document.getElementById("decimals-input").value = 12;
    }
  };

  const plusButtonClicked = () => {
    if (isNaN(parseInt(document.getElementById("decimals-input").value))) {
      document.getElementById("decimals-input").value = 18;
    } else if (parseInt(document.getElementById("decimals-input").value) < 18) {
      document.getElementById("decimals-input").value =
        parseInt(document.getElementById("decimals-input").value) + 1;
    } else {
      document.getElementById("decimals-input").value = 18;
    }
  };

  function nextButtonClicked(event) {
    setTokenNameError(false);
    setSymbolError(false);
    setDecimalsError(false);
    setDescriptionError(false);
    let error = false;
    if (document.getElementById("token-name-input").value == "") {
      setErrorText("Please chose a token name");
      setTokenNameError(true);
      error = true;
    }
    if (document.getElementById("symbol-input").value == "") {
      setErrorText("Please specify a the token symbol");
      setSymbolError(true);
      error = true;
    }
    const num = Number(document.getElementById("decimals-input").value);
    if (
      document.getElementById("decimals-input").value === "" ||
      !Number.isInteger(num) ||
      parseInt(document.getElementById("decimals-input").value) < 12 ||
      parseInt(document.getElementById("decimals-input").value) > 18
    ) {
      setErrorText(
        "Please specify a correct token decimal count between 12 and 18"
      );
      setDecimalsError(true);
      error = true;
    }
    if (document.getElementById("description-input").value == "") {
      setErrorText("Please fill in the token description");
      setDescriptionError(true);
      error = true;
    }

    // if (imageimage === "" && uploadedImage === "") {
    //   setErrorText("Please upload an image of your logo");
    //   console.log("imageError: " + imageimage);
    //   error = true;
    // }
    if (error == true) {
      return;
    }

    //console.log("coin logo: " + coinLogo + " asd " + imageimage);
    //setNext(true);
    setErrorText("");
    setShowBlockchainDropdown(false);
    props.moveToNextStep(true);
    props.stepOneActive(false);
    props.setTokenName(document.getElementById("token-name-input").value);
    props.setSymbol(document.getElementById("symbol-input").value);
    props.setDecimals(document.getElementById("decimals-input").value);
    props.setDescription(document.getElementById("description-input").value);
    props.setBlockchainName(blockchainName);
    if (imageimage !== "") {
      setCoinLogo(imageimage);
      props.setCoinLogo(imageimage);
    }
    props.setStepTwoWasActive(true);
    props.scrollToTop();
  }

  function changeBlockchain(name, image) {
    setBlockchainLogo(image);
    setBlockchainName(name);
    setShowBlockchainDropdown(false);
  }

  const BlockchainDropdownDiv = (props) => {
    const { ref, isComponentVisible, setIsComponentVisible } =
      useComponentVisible(props.showBlockchainDropdown);
    useEffect(() => {
      props.setShowBlockchainDropdown(isComponentVisible);
    }, [isComponentVisible]);
    useEffect(() => {
      setIsComponentVisible(props.showBlockchainDropdown);
    }, [props.showBlockchainDropdown]);
    const listItems = Constants["chains"].map((chain) => (
      <div
        onClick={() =>
          changeBlockchain(chain.name, require("../img/coinLogos/" + chain.img))
        }
        className="step-one-blockchain-dropdown-item"
      >
        <img
          src={require("../img/coinLogos/" + chain.img)}
          className="blockchain-dropdown-item-logo"
          alt={chain.alt}
        />
        <span className="step-one-block-dropdown-item-name">{chain.name}</span>
      </div>
    ));
    return (
      <div ref={ref}>
        {isComponentVisible && (
          <div className="step-oneblockchain-dropdown-div" ref={ref}>
            <div
              className="step-one-blockchain-dropdown-item-top"
              onClick={() => setShowBlockchainDropdown(false)}
            >
              <img
                src={blockchainLogo}
                className="blockchain-dropdown-item-logo-top"
                alt="blockchain-logo"
              />
              <span className="step-one-block-dropdown-item-name-top">
                {blockchainName}
              </span>
            </div>
            <svg
              className="step-oneblockchain-dropdown-div-seperator"
              width="488px"
              height="1"
            >
              <rect width="100%" height="1" />
            </svg>
            <div className="step-one-blockchain-dropdown-items">
              {listItems}
            </div>
          </div>
        )}
      </div>
    );
  };

  function Preview(props) {
    const onDrop = useCallback((acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        console.log("test");
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
          console.log(binaryStr);
          setImageimage(binaryStr);
          console.log("file read");
          console.log("img:" + imageimage);
        };
        reader.readAsDataURL(file);
        console.log(reader);

        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      });
    }, []);

    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
      accept: {
        "image/*": [],
      },
      maxFiles: 1,
      onDrop,
    });

    useEffect(() => {
      if (files.length) {
        setImageAsync(files[0].preview);
      }
    }, [files.length]);
    useEffect(() => {
      // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
      return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    }, []);

    if (files.length) {
      return (
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <div className="drag-image-icon-wrapper">
            <img
              className="drag-image-icon"
              alt="drag image icon"
              src={files[0].preview}
              onLoad={() => {
                URL.revokeObjectURL(files[0].preview);
              }}
            />
          </div>
        </div>
      );
    } else {
      console.log("img:" + uploadedImage);
      return (
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <div className="drag-image-icon-wrapper">
            {uploadedImage === "" ? (
              <img
                src={DragImageIcon}
                className="drag-image-icon"
                alt="drag image icon"
              />
            ) : (
              <img
                src={uploadedImage}
                className="drag-image-icon"
                alt="drag image icon"
              />
            )}
          </div>
        </div>
      );
    }
  }

  function getBottomErrorText() {
    if (errorText !== "") {
      return (
        <div className="step-one-bottom-error">
          <img
            src={WarningIcon}
            className="step-one-error-warning-icon"
            alt="error"
          />
          <span className="step-one-error-text">{errorText}</span>
        </div>
      );
    }
  }

  return (
    <div className="step-one-background">
      <span className="step-one-basic-informations-span-404 h1">ERC-404</span>
      <span className="step-one-header">Step 1</span>
      <span className="step-one-basic-informations-span-404">
        Basic Information
      </span>
      <div className="drag-coin-image-div">
        <span className="coin-image-description">Coin image</span>
        {/*<img src={DragImageIcon} className="drag-image-icon" alt="drag image icon"/>*/}
        <Preview />
      </div>
      <div className="step-one-token-name-input-div">
        <span className="step-one-input-box-description">
          Token name
          {tokenNameError && (
            <span className="input-error-text">Please chose a token name</span>
          )}
        </span>
        <input
          type="text"
          autoComplete="off"
          className={"step-one-token-name-input ".concat(
            tokenNameError ? "input-error" : ""
          )}
          id="token-name-input"
          name="fname"
          placeholder="e.g. Swaple, Tokeniki, Exhibit Art Token"
        />
      </div>
      <div className="step-one-token-name-input-div">
        <span className="step-one-input-box-description">
          Symbol
          {symbolError && (
            <span className="input-error-text">
              Please specify a token symbol
            </span>
          )}
        </span>
        <input
          type="text"
          autoComplete="off"
          className={"step-one-token-name-input ".concat(
            symbolError ? "input-error" : ""
          )}
          id="symbol-input"
          name="fname"
          placeholder="e.g. SWPL, TKNK, EXHIB"
        ></input>
      </div>
      <div className="step-one-token-name-input-div-wide">
        <span className="step-one-input-box-description">Blockchain</span>
        <div
          className="step-one-blockchain-combobox-outline-wide"
          onClick={blockchainDropdownClicked}
        >
          <img
            src={blockchainLogo}
            className="step-one-blockchain-logo"
            alt="blockchain-logo"
          />
          <span className="step-one-blockchain-name">{blockchainName}</span>
          <svg height="8" width="15" className="step-one-dropdown-polygon">
            <polygon points="0,0 15,0 7.5,8 " fill="#5f758e" stroke="#5f758e" />
          </svg>
        </div>
        {showBlockchainDropdown ? (
          <BlockchainDropdownDiv
            showBlockchainDropdown={showBlockchainDropdown}
            setShowBlockchainDropdown={setShowBlockchainDropdown}
          />
        ) : null}
      </div>
      <div className="step-one-token-name-input-div-wide">
        <span className="step-one-input-box-description">
          Decimals
          {decimalsError && (
            <span className="input-error-text">
              Please specify a correct token decimal count between 12 and 18
            </span>
          )}
        </span>
        <input
          type="text"
          autoComplete="off"
          className={"step-one-token-name-input-wide ".concat(
            decimalsError ? "input-error" : ""
          )}
          id="decimals-input"
          name="fname"
          placeholder="Decimal precision of your token, 12-18 (recommended: 18)"
          onChange={(e) => {
            let value = e.target.value;
            if (value > 18) {
              value = 18;
            }
            setDecimalsTyped(value);
          }}
        ></input>
        <span className="step-one-minus-button" onClick={minusButtonClicked}>
          -
        </span>
        <span className="step-one-plus-button" onClick={plusButtonClicked}>
          +
        </span>
      </div>
      <div className="step-one-token-name-input-div-wide">
        <span className="step-one-input-box-description">
          Description
          {tokenNameError && (
            <span className="input-error-text">
              Please fill in the token description
            </span>
          )}
        </span>
        <TextareaAutosize
          minRows={3}
          maxRows={5}
          type="text"
          autoComplete="off"
          className={"step-one-token-name-input-wide step-one-text-area".concat(
            descriptionError ? "input-error" : ""
          )}
          id="description-input"
          name="fname"
          placeholder="e.g. DeFi staking, pool and farming token of Tokeniki"
        />
      </div>

      {/*<div className="step-one-token-channels">
                <span className="step-one-token-channel">Add token website</span>
                <span className="step-one-token-channel">Add token Twitter</span>
                <span className="step-one-token-channel">Add token Telegram</span>
                <span className="step-one-token-channel">Add other token channel</span>
            </div>*/}

      {getBottomErrorText()}

      <button
        className="step-one-next-button default-button"
        onClick={nextButtonClicked}
      >
        Next
      </button>
    </div>
  );
};

export default BuilderStepOne;
