import './Header.css';
import './styles.css'
import TokenikiLogo from '../img/logo_tokeniki.svg'
import BlockfactoryLogo from '../img/logo_blockfactory.svg'
import ConnectWalletIcon from '../img/icons/wallet.svg'
import LinkButton from './LinkButton';
import { ethers } from "ethers";
import React, { useState, useEffect, useRef } from "react";

const Header = (props) => {

    const [walletConnected, setWalletConnected] = React.useState(false);
    const [userWalletAddress, setUserWalletAddress] = React.useState("");

    async function connect(){
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        var address = await signer.getAddress()
        console.log("Account:", await signer.getAddress());
        
        setUserWalletAddress("0x...".concat(address.slice(address.length - 4)));
        props.setWalletConnected(true);
        setWalletConnected(true);
        localStorage.setItem("hasConnectedWalletAtLeastOnce", "1");
    }

    async function disconnect(){
        //TODO - nie ma takiej funkcji, można jedynie słuchać discconecty
    }

    function ConnectWalletButton(){//<span className='header-button-text'>Connect Wallet</span>
        return(
            <>
                <img src={ConnectWalletIcon} className="header-connect-wallet-icon" alt="blockchain-logo"/>
                <span className='header-button-text'>Connect Wallet</span>
            </>
        )
    }
    function ConnectedWalletButton(){//<span className='header-button-text'>Connect Wallet</span>
        return(
                <span className='header-button-text-connected'>Connected {userWalletAddress}</span>          
        )
    }



    useEffect(() => {
        async function updateUserWalletAddress(){
            const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            var address = await signer.getAddress()
            setUserWalletAddress("0x...".concat(address.slice(address.length - 4)));
        }
        if(props.walletConnected==true){
            updateUserWalletAddress();
        }
        setWalletConnected(props.walletConnected);
        
    }, [props.walletConnected])

    useEffect(() => {
        if(localStorage.getItem("hasConnectedWalletAtLeastOnce") == 1 && walletConnected==false){
            connect()
        }
    },[])




    return (
        <div className="header-background">
            <LinkButton className="header-logo" to='/'>
                <img src={BlockfactoryLogo} className="header-logo-image" alt="BlockFactory Logo" />
            </LinkButton>
            <button className='header-button default-button' onClick={connect}>
                {walletConnected ? <ConnectedWalletButton /> : <ConnectWalletButton /> }          
            </button>
        
        </div>

    )
}

export default Header;
