import './MobileNoSupportPage.css'
import NoSupportImage from '../img/img_mobile-disabled.jpg'

const MobileNoSupportPage = (props) => {


    return (
        <div className='mobile-div'>
            <img className='mobile-img' src="https://blockfactory.app/wp-content/uploads/2023/01/img_mobile-disabled.jpg" alt="image"/>
            <h2 className='mobile-h2'>Mobile is not currently supported</h2>
            <p className='mobile-p'>The mobile version is under development. Please check back soon or open the token creator on your PC.</p>
        </div>
    )
}

export default MobileNoSupportPage;