import './TokenhubTokenView.css';
import './../styles.css'
import { ethers } from "ethers";
import React, { useState, useEffect, useRef } from "react";
import DragImageIcon from '../../img/dragdropScreenshot.png';
import PauseIcon from '../../img/icons/pause.svg';
import ResumeIcon from '../../img/icons/resume.svg';
import SnapshotIcon from '../../img/icons/snapshot.svg';
import XIcon from '../../img/icons/x.svg';
import EditIcon from '../../img/icons/edit.svg';
import LinkIcon from '../../img/icons/link.svg';
import TwitterIcon from '../../img/icons/twitter.svg';
import TelegramIcon from '../../img/icons/telegram.svg';
import DiscordIcon from '../../img/icons/discord.svg';
import TokenhubTokenViewTools from './TokenhubTokenViewTools.js';
import TokenhubTokenViewContract from './TokenhubTokenViewContract.js'
import EditTokenPopup from './EditTokenPopup.js'
import Constants from '../../constants.json'
import { animated, useSpring, easings } from '@react-spring/web'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
  } from "react-router-dom";


function useComponentVisible(initialIsVisible) { // wykrywa klikniecia poza popupem zeby go zamknac wtedy
    const [isComponentVisible, setIsComponentVisible] = useState(
      initialIsVisible
    );
    const ref = useRef(null);
  
    const handleHideDropdown = (event) => {
      if (event.key === "Escape") {
        setIsComponentVisible(false);
      }
    };
  
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsComponentVisible(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener("keydown", handleHideDropdown, true);
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("keydown", handleHideDropdown, true);
        document.removeEventListener("click", handleClickOutside, true);
      };
    });
  
    return { ref, isComponentVisible, setIsComponentVisible };
}


const TokenhubTokenView = (props) => {

    const [walletConnected, setWalletConnected] = React.useState(props.walletConnected);
    const [tokenData, setTokenData] = React.useState(props.tokenData);
    const [authorizationTime, setAuthorizationTime] = React.useState(-1);
    const [toolsSelected, setToolsSelected] = React.useState(true);
    const [signature, setSignature] = React.useState("");
    const [coinLogo, setCoinLogo] = React.useState("");
    const [notificationActive, setNotificationActive] = React.useState(false);
    const [notificationHeaderText, setNotificationHeaderText] = React.useState("");
    const [notificationText, setNotificationText] = React.useState("");
    const [notificationColour, setNotificationColour] = React.useState("");
    const [showDescriptionPopup, setShowDescriptionPopup] = React.useState(false);
    const [showEditPopup, setShowEditPopup] = React.useState(false);
    var timer;


    useEffect(() => {
        if(Object.keys(props.tokenData).length === 0){
            RedirectToMain();
        }
    },[])

    useEffect(() => {
        setWalletConnected(props.walletConnected);
        /*var chainID="";

        const checkChain = async () => {
            const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            const walletChainId = await provider.getNetwork()
            console.log("wallet chain id: " + walletChainId.chainId);
            if (walletChainId.chainId != chainID){
                setIsChainCorrect(false);
                console.log("wallet chain id: " + walletChainId.chainId + ", chainid: " + chainID);
            }
            else{
                setIsChainCorrect(true);
            }
        }
        checkChain()*/
        
    }, [props.walletConnected])



    useEffect(() => {
        setTokenData(props.tokenData);
        //setCoinLogo(tokenData["logo"].substring(0, 21) + "," + tokenData["logo"].substring(22));
        setCoinLogo(tokenData["logo"])
    }, [props.tokenData])

    useEffect(() => {   //get data from blockchain
        if (signature !=''){
            getRecoverableTokens();
            checkPausedStatus();
        }
    },[signature])



    function test(){
        setNotificationActive(false);
        addNotification("green", "Snapshot has been taken", "Snapshot date: 10 October 2022, 19:54")
    }

    
    function addNotification(colour, header, text){
        setNotificationActive(true);
        setNotificationHeaderText(header);
        setNotificationText(text);
        setNotificationColour(colour);
        if(colour !== "red"){
            timer = setTimeout(() => {
                setNotificationActive(false);
              }, 5000);
            return () => clearTimeout(timer);
        }
        else{
            clearInterval(timer)
        }
    }

    function removeNotification(){
        setNotificationActive(false);
    }

    function GetNotificationPopup() {
        let bgColour = "";
        const styles = useSpring({
            from: { 
                right: -350,
                opacity: 0,
            },
            to: { 
                right: 24, 
                opacity: 1, 
            },
          config: { 
            duration: 200,
            easing: easings.easeInOutBack, 
        },
          reset: true,
        })
        if (!notificationActive){
            return
        }
        let colour = notificationColour
        let backgroundStyle = "";
        let headerStyle = "";
        if (colour === 'green'){
            backgroundStyle = 'tokenhub-notification-green-bg'
            headerStyle = 'tokenhub-notification-green-header'
            bgColour="#e8fae5"
        }
        else if (colour === 'orange'){
            backgroundStyle = 'tokenhub-notification-orange-bg'
            headerStyle = 'tokenhub-notification-orange-header'
            bgColour="#fff9e5"
        }
        else{
            backgroundStyle = 'tokenhub-notification-red-bg'
            headerStyle = 'tokenhub-notification-red-header'
            bgColour="#fbe7e9"
        }
        return (
          <animated.div
            
            style={{
                position: 'fixed',
                top: 143,
                width: 350,
                borderRadius: 12,
                zIndex: 1,
                minHeight: 55,
                backgroundColor: bgColour,
                ...styles,
            }}
          >
            <span className={'tokenhub-notification-header ' + headerStyle}>{notificationHeaderText}</span>
            <span className={'tokenhub-notification-text ' + backgroundStyle}>{notificationText}</span>
            <button className={'tokenhub-notification-close-button ' + headerStyle} onClick={() => setNotificationActive(false)}>x</button>
          </animated.div>
        )
    }


    async function getRecoverableTokens(){
        const abi = [
            "function balanceOf(address account) external view returns (uint256 balance)"
        ]
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(tokenData["token_address"], abi, signer)
        const balance = await contract.balanceOf(tokenData["token_address"]);
        console.log(balance);

    }

    async function checkPausedStatus(){
        const abi = [
            "function paused() public view virtual returns (bool)"
        ]
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(tokenData["token_address"], abi, signer)
        const paused = await contract.paused();
        const address = await signer.getAddress();
        console.log("paused: " + paused);
        if (paused){
            if (tokenData["paused"] == "0"){
                fetch(Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + signature + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                "&paused=1").then((response) => {
                    return response.text()}).then((responseText) => {
                        const myObj2 = JSON.parse(responseText)
                        console.log("response: " + responseText);
                        let myObj = {...tokenData};
                        myObj["paused"] = "1";
                        setTokenData(myObj);
                    })
            }
        }
        else{
            if (tokenData["paused"] == "1"){
                fetch(Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + signature + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                "&paused=0").then((response) => {
                    return response.text()}).then((responseText) => {
                        const myObj2 = JSON.parse(responseText)
                        console.log("response: " + responseText);
                        let myObj = {...tokenData};
                        myObj["paused"] = "0";
                        setTokenData(myObj);
                    })
            }
        }
    }

    function getChainName(){
        if(Object.keys(props.tokenData).length === 0){
            RedirectToMain();
        }
        const chainID = tokenData["chainid"];
        /*let blockchainName = "" 
        if (chainID == "97"){
            blockchainName="BSC Testnet"
        }
        else if (chainID == "5"){
            blockchainName="Goerli"
        }
        else if (chainID == "63"){
            blockchainName="Mordor"
        }
        else if (chainID == "11155111"){
            blockchainName="Sepolia"
        }
        return (blockchainName)*/
        return(Constants['chainNames'][chainID]);
    }



    function getPauseButton(){
        const pausable = tokenData["pausable"];
        if (pausable == "0"){
            return (<></>);
        }
        if (tokenData["paused"] == "0"){
            return(
                <button className='tokenhub-tv-pause-button default-button-secondary' onClick={() => authorizeThenExecute(pauseToken)}>
                    <img src={PauseIcon} className='tokenhub-tv-pause-icon'/>
                    Pause token
                </button>
            )
        }
        else{
            return(
                <button className='tokenhub-tv-resume-button default-button-alert' onClick={() => authorizeThenExecute(resumeToken)}>
                    <img src={ResumeIcon} className='tokenhub-tv-pause-icon'/>
                    Resume token
                </button>
            )
        }

    }
    async function pauseToken(sig){
        const abi = [
            "function pause()"
        ]
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(tokenData["token_address"], abi, signer)
        contract.pause().then(transaction => {
            removeNotification();
            addNotification("green", "Token paused", "");
            console.log(transaction);
            let myObj = {...tokenData};
            myObj["paused"] = "1";
            setTokenData(myObj);
            signer.getAddress().then(address => {
                console.log("request: " + Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                    "&paused=1")
                fetch(Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                    "&paused=1").then((response) => {
                    return response.text()}).then((responseText) => {
                        const myObj2 = JSON.parse(responseText)
                        console.log("response: " + responseText);
                    })
            })
        },
        (error) =>{
            console.log("error " + error)
            setNotificationActive(false);
            addNotification("red", "Failed to pause token", "");
        });
    }

    async function resumeToken(sig){
        //authorize()
        const abi = [
            "function unpause()"
        ]
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(tokenData["token_address"], abi, signer)
        contract.unpause().then(transaction => {
            removeNotification();
            addNotification("green", "Token resumed", "");
            console.log(transaction);
            let myObj = {...tokenData};
            myObj["paused"] = "0";
            setTokenData(myObj);
            signer.getAddress().then(address => {
                console.log("request: " + Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                    "&paused=0")
                fetch(Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                    "&paused=0").then((response) => {
                    return response.text()}).then((responseText) => {
                        const myObj2 = JSON.parse(responseText)
                        console.log("response: " + responseText);
                    })
            })
        },
        (error) => {
            console.log("error " + error)
            setNotificationActive(false);
            addNotification("red", "Failed to resume token", "");
        });
    }

    function getSnapshotButton(){
        const snapshot = tokenData["snapshots"];
        if (snapshot == "0"){
            return (<></>);
        }
        return(
            <button className='tokenhub-tv-snapshot-button default-button' onClick={() => authorizeThenExecute(takeSnapshot)}>
                <img src={SnapshotIcon} className='tokenhub-tv-pause-icon'/>
                Take snapshot
            </button>
        )

    }

    async function takeSnapshot(sig){
        const abi = [
            "function snapshot()"
        ]
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(tokenData["token_address"], abi, signer)
        contract.snapshot().then(transaction => {
            console.log(transaction);
            signer.getAddress().then(address => {
                let timeNow = new Date().getTime();
                console.log("request: " + Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                "&new_snapshot=" + timeNow)
                fetch(Constants['database'] + '/builder/edit/?'+ 'wallet=' + address + "&signature=" + sig + "&token_contract=" + tokenData["token_address"] + "&chainid=" + tokenData["chainid"] + 
                    "&new_snapshot=" + timeNow).then((response) => {
                    return response.text()}).then((responseText) => {
                        const myObj2 = JSON.parse(responseText)
                        console.log("response:" + responseText);
                        let myObj = {...tokenData};
                        myObj['snapshots_list']['snapshots'].push(timeNow / 1000);
                        setTokenData(myObj);
                        var today = new Date();
                        setNotificationActive(false);
                        addNotification("green", "Snapshot has been taken", "Snapshot date: " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds());
                    })
            })

        },
        (error) => {
            console.log("error " + error)
            setNotificationActive(false);
            addNotification("red", "Failed to take snapshot", "");
        });
    }

    function getTabSelect(){
        if (toolsSelected){
            return(
                <div className='tokenhub-tv-tab-select'>
                        <div className='tokenhub-tv-tab'>
                            <span className='tokenhub-tv-active-tab-title'>Tools</span>
                            <svg className="tokenhub-tv-active-step-highlight" width="100%" height="3">
                                <rect width="100%" height="4" />
                            </svg>
                        </div>
                        <div className='tokenhub-tv-tab' onClick={() => setToolsSelected(/*change to false when contract tab is done*/ true)}>
                            <span className='tokenhub-tv-inactive-tab-title'>Contract</span>
                        </div>
                </div>
            )
        }
        else{
            return(
                <div className='tokenhub-tv-tab-select'>
                        <div className='tokenhub-tv-tab' onClick={() => setToolsSelected(true)}>
                            <span className='tokenhub-tv-inactive-tab-title'>Tools</span>
                        </div>
                        <div className='tokenhub-tv-tab'>
                            <span className='tokenhub-tv-active-tab-title'>Contract</span>
                            <svg className="tokenhub-tv-active-step-highlight" width="100%" height="3">
                                <rect width="100%" height="4" />
                            </svg>
                        </div>
                </div>
            )
        }

    }

    function RedirectToMain(){
        let history = useHistory();
        history.push("/");
    }

    function getDescritpion(){
        if(Object.keys(props.tokenData).length === 0){
            RedirectToMain();
        }
        if(tokenData["description"].length < 200){
            return tokenData["description"]
        }
        else{
            return(
                <>
                    <span>{tokenData["description"].substring(0,195) + "..."}</span>
                    <span className='tokenhub-tv-read-more' onClick={readMoreClicked}>Read more</span>
                </>
            )
        } 
    }

    const readMoreClicked = () =>  {
        if(showDescriptionPopup){
            setShowDescriptionPopup(false);
        }
        else {
            setShowDescriptionPopup(true);
        }
    }

    const DescriptionPopup = (props) => {
        const {
            ref,
            isComponentVisible,
            setIsComponentVisible
          } = useComponentVisible(props.showDescriptionPopup);
        useEffect(() => {
            props.setShowDescriptionPopup(isComponentVisible)
        }, [isComponentVisible])
        useEffect(() => {
            setIsComponentVisible(props.showDescriptionPopup)
        }, [props.showDescriptionPopup])
        return(
            <div ref={ref}>
            {isComponentVisible && (
                <div className="tokenhub-tv-description-popup-background" ref={ref} onClick={() => setShowDescriptionPopup(false)}>
                    <div className='tokenhub-tv-description-popup'>
                        <span className='tokenhub-tv-description-popup-title'>Description</span>
                        <img src={XIcon} className="tokenhub-tv-description-popup-close-icon"/>
                        <svg className="tokenhub-tv-description-popup-divider" width="100%" height="1">
                            <rect width="100%" height="1" />
                        </svg>
                        <span className='tokenhub-tv-description-popup-description'>{tokenData["description"]}</span>
                    </div>
                </div>
                )}
            </div>
        )
    }

    function getSocials(){

        return(
            <div className='tokenhub-tv-socials'>
                {tokenData['website']!=="" && <><img src={LinkIcon} className='tokenhub-tv-social-icon'/><a href={"//" + tokenData['website']} className='tokenhub-tv-social-hyperlink'>Website{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}</a></>}
                {tokenData['twitter']!=="" && <><img src={TwitterIcon} className='tokenhub-tv-social-icon'/><a href={"//" + tokenData['twitter']} className='tokenhub-tv-social-hyperlink'>Twitter{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}</a></>}
                {tokenData['telegram']!=="" && <><img src={TelegramIcon} className='tokenhub-tv-social-icon'/><a href={"//" + tokenData['telegram']} className='tokenhub-tv-social-hyperlink'>Telegram{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}</a></>}
                {tokenData['other']!=="" && <><img src={DiscordIcon} className='tokenhub-tv-social-icon'/><a href={"//" + tokenData['other']} className='tokenhub-tv-social-hyperlink'>Discord</a></>}
            </div>
        )
    }

    async function authorizeThenExecute(func, optionalParam){
        let timeNow = new Date().getTime();
        if (authorizationTime != -1 && typeof(signature !== 'undefined') && signature !== 'undefined' && Math.abs(authorizationTime - timeNow) < 1000 * 60 * 29){
            func(signature, optionalParam);
            return;
        }
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        const response = await fetch(Constants['database'] + '/builder/authorize/?'+ 'wallet=' + address + "&nonce=1").then(function(response) {
            return response.text()}).then((responseText) => {
                const myObj = JSON.parse(responseText)
                console.log("message: " + 'I am signing my one-time nonce: ' + myObj["nonce"]);
                return myObj["nonce"]
            }).then((nonce) => {
                return signer.signMessage('I am signing my one-time nonce: ' + nonce)
            }).then((sig) => {
                console.log("signature: " + sig);
                console.log("request: " + Constants['database'] + '/builder/authorize/?'+ 'wallet=' + address + "&signature=" + sig)
                fetch(Constants['database'] + '/builder/authorize/?'+ 'wallet=' + address + "&signature=" + sig).then((response) => {
                    return response.text()}).then((responseText) => {
                        //TODO add case for autheticated:false
                        const myObj2 = JSON.parse(responseText)
                        console.log("response:" + responseText);
                        setSignature(sig);
                        timeNow = new Date().getTime();
                        setAuthorizationTime(timeNow);
                        func(sig, optionalParam);
                    })
            });
    }

    function changeLogo(image){
        setCoinLogo(image)
    }

    return (
        <>
            {showDescriptionPopup ? <DescriptionPopup showDescriptionPopup={showDescriptionPopup} setShowDescriptionPopup={setShowDescriptionPopup} /> : null}
            {showEditPopup ? <EditTokenPopup tokenData={tokenData} setTokenData={setTokenData} setShowEditPopup={setShowEditPopup}
                changeLogo={changeLogo} authorizeThenExecute={authorizeThenExecute}/> : null}
            {GetNotificationPopup()}
            <div className="tokenhub-tv-background">
                <div className="tokenhub-tv-header">
                    <div className='tokenhub-tv-logo-box'>
                        <img src={coinLogo} className='tokenhub-tv-logo'/>
                    </div>
                    <span className='tokenhub-tv-name-ticker'>
                        {tokenData["name"]}
                        <span className='tokenhub-tv-ticker'>{" #" + tokenData["symbol"]}</span>
                    </span>
                    <div className='tokenhub-tv-chain-info'>
                        <span className='tokenhub-tv-chain-name'>{getChainName()}</span>
                        <div className='tokenhub-tv-circle-icon'/>
                        <span className='tokenhub-tv-chain-name'>{tokenData["token_type"]}</span>
                    </div>
                    <div className='tokenhub-tv-description-and-socials'>
                        <span className='tokenhub-tv-description'>{getDescritpion()}</span>
                        {getSocials()}
                    </div>

                    {getPauseButton()}
                    {getSnapshotButton()}
                    <button className='tokenhub-tv-edit-button' onClick={() => setShowEditPopup(true)}>
                        <img src={EditIcon} className='tokenhub-tv-edit-button-icon'/>
                        <span className='tokenhub-tv-edit-button-text'>Edit</span>
                    </button>
                </div>
                {getTabSelect()}
                {toolsSelected ? <TokenhubTokenViewTools setTokenData={setTokenData} tokenData={tokenData} signature={signature} setSignature={setSignature} removeNotification={removeNotification}
                    authorizationTime={authorizationTime} setAuthorizationTime={setAuthorizationTime} authorizeThenExecute={authorizeThenExecute} addNotification={addNotification} setNotificationActive={setNotificationActive}/> : 
                    <TokenhubTokenViewContract setTokenData={setTokenData} tokenData={tokenData}/>}
                
            </div>
        </>
        
    )
}

export default TokenhubTokenView;