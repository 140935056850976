import './ErrorOverlay.css';
import './styles.css';
import React, { useState, useEffect, useRef } from "react";
import StepFourIcon from '../img/cube4_disabled.png';

const ErrorOverlay = (props) => {


    return (
        <div className="error-overlay-background">
            <div className="error-overlay-box">
                <span className='error-overlay-title'>An error has occured, pleased try again later</span>
                <span className='error-overlay-info-text'>If this error repeats, please contact us on twitter @_BlockFactory</span>
                <img className='error-overlay-icon' src={StepFourIcon}/> 
                <button className='default-button error-overlay-close-button' onClick={() => props.setShowErrorOverlay(false)}>Close</button>
            </div>
        </div>

    )
}

export default ErrorOverlay;